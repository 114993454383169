<template>
  <main
    class="summary-blog-summary-blog card-rows-container"
    v-bind:class="rootClassName"
  >
    <div class="card-row">
      <section class="character-teaser-container">
        <h1 class="card-heading">Funktioniert LARP auch online?</h1>
        <p class="card-text">
          P&amp;P-Rollenspiel gibt es schon lange, auch online, und schon vor
          Corona um trotz grosser Distanzen gemeinsam spielen zu können. Was sind
          also die Unterschiede?
        </p>
        <router-link to="/blog/funktioniert-larp-auch-online">
          <div class="summary-blog-button more-link">
            <p class="card-link">{{ lesen }}</p>
          </div>
        </router-link>
      </section>
      <section class="character-teaser-container">
        <h1 class="card-heading">
          Wieso Itras By ein so simples wie grandioses P&amp;P ist
        </h1>
        <p class="card-text">
          Das Spielkonzept von Itras By begünstigt Offenheit und abrupte
          Änderungen des Plots. Diesen erschaffen die Spielenden und die
          Spielleitung gemeinsam.
        </p>
        <router-link
          to="/blog/wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist"
        >
          <div class="summary-blog-button1 more-link">
            <p class="card-link">{{ lesen }}</p>
          </div>
        </router-link>
      </section>
    </div>
    <div class="card-row">
      <section class="character-teaser-container">
        <h1 class="card-heading">
          Wie man ein gutes Online LARP macht (und wie nicht)
        </h1>
        <p class="card-text">
          Ein gutes Online LARP braucht Immersion, Selbstständigkeit des Spielers
          und vordefinierte Beziehungen. Wie kann man das erreichen?
        </p>
        <router-link to="/blog/wie-man-ein-gutes-online-larp-macht-und-wie-nicht">
          <div class="summary-blog-button2 more-link">
            <p class="card-link">{{ lesen }}</p>
          </div>
        </router-link>
      </section>
      <section class="character-teaser-container">
        <h1 class="card-heading">
          Wie Tales Inside eine einmalige Immersion erreicht
        </h1>
        <p class="card-text">
          Tales Inside ist ein Vorbild was Immersion im LARP angeht. Um
          herauszufinden, was eben diese ausmacht, habe ich 10 Teilnehmende
          interviewt. Hier die Resultate.
        </p>
        <router-link
          to="/blog/wie-tales-inside-eine-einmalige-immersion-erreicht"
        >
          <div class="summary-blog-button3 more-link">
            <p class="card-link">{{ lesen }}</p>
          </div>
        </router-link>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'SummaryBlog',
  props: {
    lesen: {
      type: String,
      default: 'Lesen ->',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.summary-blog-summary-blog {
  position: relative;
}
.summary-blog-button {
  text-decoration: none;
}
.summary-blog-button:hover {
  opacity: 0.5;
}
.summary-blog-button1 {
  text-decoration: none;
}
.summary-blog-button1:hover {
  opacity: 0.5;
}
.summary-blog-button2 {
  text-decoration: none;
}
.summary-blog-button2:hover {
  opacity: 0.5;
}
.summary-blog-button3 {
  text-decoration: none;
}
.summary-blog-button3:hover {
  opacity: 0.5;
}

</style>
