<template>
  <div class="larpkonzept-container">
    <app-navigation rootClassName="navigation-root-class-name"></app-navigation>
    <h1 class="page-title">
      <span>Larpkonzept</span>
      <br />
    </h1>
    <span class="page-subtitle">
      <span>
        Für eine sichere Durchführung von intensiven, emotionalen und
        kontrastreichen LARPs
      </span>
      <br />
    </span>
    <a
      href="https://docs.google.com/presentation/d/1vgGPkM1BdSP3smg-d_qFOz0lCks8BZ_P6cLRgP1mDWY/edit?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="button"
    >
      <span class="button-text">zum Larpkonzept</span>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name2"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import AppKontakt from '../components/kontakt'

export default {
  name: 'Larpkonzept',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Larpkonzept',
    meta: [
      {
        name: 'description',
        content:
          'Für eine sichere Durchführung von intensiven, emotionalen und kontrastreichen LARPs.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Larpkonzept',
      },
      {
        property: 'og:description',
        content:
          'Für eine sichere Durchführung von intensiven, emotionalen und kontrastreichen LARPs.',
      },
    ],
  },
}
</script>

<style scoped>
.larpkonzept-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
</style>
