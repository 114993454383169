<template>
  <div class="ueber-uns-container">
    <app-navigation rootClassName="navigation-root-class-name10"></app-navigation>
    <h1 class="page-title">
      <span>Über uns</span>
      <br />
    </h1>
    <div class="text-body">
      <span>
        <span>
          OpenVisor ist eine Non-Profit-Marke unter welcher Geschichten erfunden
          und erzählt werden und allerlei damit verbundene Kunst. OpenVisor
          garantiert, dass alle ihre Inhalte frei verfügbar bleiben. Bei LARPs,
          die noch kommende Veranstaltungen haben, werden die Inhalte erst nach
          der letzten Durchführung veröffentlicht, um Spoiler zu vermeiden.
        </span>
        <br />
        <br />
        <span>
          Bis jetzt hat nur Hermann Werke unter der OpenVisor Marke erstellt, aber
          man darf gerne mit ihm in Kontakt treten, wenn man hier etwas
          veröffentlichen möchte.
        </span>
        <br />
        <br />
        <span>
          Vielleicht sind wir uns ja schonmal über den Weg gelaufen, ohne es zu
          merken. Folgend ist eine Liste von LARPs und ähnlichen Veranstaltungen,
          an denen Hermann teilgenommen hat oder noch teilnehmen wird. :)
        </span>
      </span>
    </div>
    <a
      href="https://docs.google.com/spreadsheets/d/1fWIJHFUjcbE5a04M3eIBmRVvBI2bYcDbsys3mc5r7h0/edit?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="ueber-uns-link button"
    >
      <span class="button-text">Hermanns Larperfahrung</span>
    </a>
    <app-kontakt></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import AppKontakt from '../components/kontakt'

export default {
  name: 'UeberUns',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Über uns',
    meta: [
      {
        property: 'og:title',
        content: 'OpenVisor - Über uns',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.ueber-uns-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
}
.ueber-uns-link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
</style>
