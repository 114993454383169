<template>
  <div class="blog-container">
    <app-navigation rootClassName="navigation-root-class-name43"></app-navigation>
    <h1 class="page-title">Blog</h1>
    <div class="text-body">
      <span>
        In diesem Blog sammle ich Recherchen und Erfahrungsberichte zu allerlei
        Rollenspielen.
      </span>
    </div>
    <div class="card-container"><summary-blog></summary-blog></div>
    <app-kontakt rootClassName="kontakt-root-class-name31"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import SummaryBlog from '../components/summary-blog'
import AppKontakt from '../components/kontakt'

export default {
  name: 'Blog',
  props: {},
  components: {
    AppNavigation,
    SummaryBlog,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Blog',
    meta: [
      {
        name: 'description',
        content:
          'In diesem Blog sammle ich Recherchen und Erfahrungsberichte zu allerlei Rollenspielen.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Blog',
      },
      {
        property: 'og:description',
        content:
          'In diesem Blog sammle ich Recherchen und Erfahrungsberichte zu allerlei Rollenspielen.',
      },
    ],
  },
}
</script>

<style scoped>
.blog-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
</style>
