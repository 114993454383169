<template>
  <main class="card-rows-container" v-bind:class="rootClassName">
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Süsse Träume</h3>
        <p class="card-text">
          <span>
            &quot;Weisst du, wer du bist? Was du bereust, befürchtest, vergessen
            möchtest? Was du erreichen möchtest und was dir alles recht ist um
            dies zu erreichen? Zeit, deine Antworten auf diese Fragen auf die
            Probe zu stellen...&quot;
          </span>
          <br />
          <br />
          <span>
            Süsse Träume war ein Fantasy Minilarp (nicht Nordic) im Wald von
            Eschikon (8315 Lindau, Schweiz) das Sue und Hermann organisierten. Es
            ging darum, dass ein Hexer Trajar die Spielercharaktere als diese sich
            schlafen legten in seine Traumwelt entführte.
          </span>
        </p>
        <router-link to="/larps/suesse-traeume">
          <mehr-button class="summary-larps-component"></mehr-button>
        </router-link>
      </div>
      <section class="character-teaser-container">
        <h3 class="card-heading">Letzte Gelegenheit</h3>
        <p class="card-text">
          Das Nordic LARP “Letzte Gelegenheit” spielt in der heutigen Zeit in
          einer alternativen Realität: Vor 10 Tagen fiel plötzlich der Strom aus
          und dichte Nebelschwaden überzogen einige Städte. Sirenen führten die
          Menschen in Bunker. Radios, die noch funktionierten, meldeten die
          sofortige Mobilisierung der Milizarmee und des Zivilschutzes. Was genau
          passierte, ist noch immer ungeklärt. In der Zivilschutzanlage der
          Justizvollzugsanstalt Schwarzbach versucht man den Alltag beizubehalten,
          doch langsam gehen die Ressourcen aus und die Lage verschärft sich.
        </p>
        <router-link to="/larps/letzte-gelegenheit">
          <mehr-button class="summary-larps-component1"></mehr-button>
        </router-link>
      </section>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Conundrum: Schatten über Ostmerkopa</h3>
        <p class="card-text">
          Hermann stellt mit Robin die Headorga von &quot;Conundrum: Schatten über
          Ostmerkopa&quot;.
        </p>
        <router-link to="/larps/conundrum-schatten-ueber-ostmerkopa">
          <mehr-button class="summary-larps-component2"></mehr-button>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import MehrButton from './mehr-button'

export default {
  name: 'SummaryLarps',
  props: {
    suesseTraeume: {
      type: String,
      default: 'Süsse Träume',
    },
    letzteGelegenheit: {
      type: String,
      default: 'Letzte Gelegenheit',
    },
    mehrLink: {
      type: String,
      default: 'Mehr ->',
    },
    rootClassName: String,
  },
  components: {
    MehrButton,
  },
}
</script>

<style scoped>
.summary-larps-component {
  text-decoration: none;
}
.summary-larps-component1 {
  text-decoration: none;
}
.summary-larps-component2 {
  text-decoration: none;
}

</style>
