<template>
  <div class="murphys-larp-container">
    <app-navigation rootClassName="navigation-root-class-name12"></app-navigation>
    <h1 class="page-title">
      <span>Murphy&apos;s LARP</span>
      <br />
    </h1>
    <span class="page-subtitle">
      <span>Post Card Drama Game, Dezember 2023</span>
      <br />
    </span>
    <div class="text-body">
      <span>
        <span class="murphys-larp-text04">・</span>
        <span class="murphys-larp-text05">
          Post Card Drama Game ab 4 Personen, die schon LARP gemacht haben, ca.
          30min inkl. Erklärung.
        </span>
        <br />
        <span>・</span>
        <span class="murphys-larp-text08">
          Spielziel ist zuerst etwas Slapstick/Fun und danach wholesome
          Verständnis/Empathie Förderung.
        </span>
        <br />
        <span>・</span>
        <span class="murphys-larp-text11">
          Erstellt von Hermann, inspiriert durch ein Gespräch mit Nils und
          playtested mit tollen Menschen.
        </span>
        <br />
        <br />
        <span>
          Alles was nur schiefgehen konnte, ging bei einem LARP schief. Ihr spielt
          die frustrierte Orga/SL (Organisation/Spielleitung) die sich über die
          “doofen” SC (Spielercharaktere) auslässt und die frustrierten SC, die
          sich über die “doofe” SL auslassen.
        </span>
        <br />
      </span>
    </div>
    <a
      href="https://drive.google.com/file/d/1Wmr0EpudlZD2UWNDRFfJ4iRRiEzrenNL/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="murphys-larp-download-button button"
    >
      <span class="button-text">Link zum PDF (Format: A5)</span>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name5"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'MurphysLARP',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: "OpenVisor - Murphy's LARP",
    meta: [
      {
        name: 'description',
        content:
          'Post Card Drama Game über ein LARP, bei dem alles schief gegangen ist.',
      },
      {
        property: 'og:title',
        content: "OpenVisor - Murphy's LARP",
      },
      {
        property: 'og:description',
        content:
          'Post Card Drama Game über ein LARP, bei dem alles schief gegangen ist.',
      },
    ],
  },
}
</script>

<style scoped>
.murphys-larp-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
.murphys-larp-download-button {
  text-decoration: none;
}
@media(max-width: 767px) {
  .murphys-larp-text04 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .murphys-larp-text05 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .murphys-larp-text08 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .murphys-larp-text11 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .murphys-larp-text05 {
    color: #ffffff;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .murphys-larp-text08 {
    color: #ffffff;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .murphys-larp-text11 {
    color: #ffffff;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
}
</style>
