<template>
  <div class="teilung-zweier-welten-container">
    <app-navigation rootClassName="navigation-root-class-name33"></app-navigation>
    <h1 class="page-title">Teilung zweier Welten</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Konflikt, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1wCZzoTmF8DAha0Lr_UOqVWaOodGbZ_Gn/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="teilung-zweier-welten-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Krieg ist seltsam. Auf dieser Seite der Grenze sorge ich mich darum,
          dass ich in Zukunft weniger Geld für meine Freizeit und
          Selbstverwirklichung habe.
        </span>
        <br />
        <br />
        <span>
          Auf der anderen Seite schläft man durch Luftschutzsirenen durch, weil
          sie so häufig sind, dass man sonst gar keinen Schlaf kriegt.
        </span>
        <br />
        <br />
        <span>
          Auf dieser Seite gibt es politischen Aktivismus, manchmal sogar Gewalt
          für eine bessere Zukunft.
        </span>
        <br />
        <br />
        <span>
          Auf der anderen Seite gibt es nur noch die Politik des Überlebens.
        </span>
        <br />
        <br />
        <span>
          Manche Menschen dürfen nicht zu meiner Seite des Zaunes kommen. Andere
          dürften, tun es aber nicht, weil sie entweder nicht willkommen sind,
          weil sie hier keine Perspektive haben oder weil sie ihre Mitmenschen und
          ihr Land nicht im Stich lassen möchten.
        </span>
        <br />
        <br />
        <span>
          Die Welten sind so anders, aber sie existieren gleichzeitig. Die
          Probleme sind so unterschiedlich. Aber wir könnten sie sein – oder sie
          könnten wir sein – wenn wir einfach an einem anderen Ort geboren wären.
          Auf der anderen Seite des Zauns.
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name22"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'TeilungZweierWelten',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Teilung zweier Welten',
    meta: [
      {
        name: 'description',
        content:
          '"Krieg ist seltsam. Auf dieser Seite der Grenze sorge ich mich darum, dass ich in Zukunft weniger Geld für meine Freizeit und Selbstverwirklichung habe. [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Teilung zweier Welten',
      },
      {
        property: 'og:description',
        content:
          '"Krieg ist seltsam. Auf dieser Seite der Grenze sorge ich mich darum, dass ich in Zukunft weniger Geld für meine Freizeit und Selbstverwirklichung habe. [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.teilung-zweier-welten-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.teilung-zweier-welten-download-button {
  text-decoration: none;
}
</style>
