<template>
  <div class="leben-statt-ueberleben-container">
    <app-navigation rootClassName="navigation-root-class-name26"></app-navigation>
    <h1 class="page-title">Leben statt Überleben</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, November 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1li9O8iGqL6mOgagHtzH4tKe6WZW8xt0J/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="leben-statt-ueberleben-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Nach der Katastrophe waren wir noch ein paar hundert Menschen im Bunker.
          Ursprünglich dachten wir, wir könnten irgendwann wieder raus an die
          Oberfläche, aber nein, wir mussten nach Monaten und Jahren feststellen,
          dass die Erde zu vergiftet ist. Mit Pilzen und Pflanzen eine
          Nahrungsmittelkette aufzubauen war nicht mal das schwierigste. Sie
          instand zu halten und den Bunker zu warten, würde dafür sorgen, dass
          niemand wirklich eine Stunde Freizeit gönnen durfte, ohne dass das
          System kollabiert. Wir berechneten Geburtenzahlen, um das System zu
          optimieren und schon vergingen Generationen.
        </span>
        <br />
        <br />
        <span>
          Anna arbeitete. Sie verliebte sich in Tom und die beiden kriegten sogar
          ein Los, dass sie ein Kind haben durften. Ein bisschen Zärtlichkeit war
          da, doch Freizeit würden auch sie nie kriegen. Den Kindern wurde auch
          nur das beigebracht, was wirklich wichtig war, um das System instand zu
          halten und der Rest, falls mal eine freie Minute da war, aber viel war
          es nicht. Sie starben 23 Jahre später.
        </span>
        <br />
        <br />
        <span>
          Ihr Kind Zoe hatte nicht das Glück eines Kinder-Loses. Als sie und ihr
          Liebhaber Karl dann trotzdem zusammen erwischt wurden, wurden sie
          verwarnt, mit der Konsequenz, dass sie sterilisiert würden, wenn sie
          weitermachten. Immerhin gab es hier keine Möglichkeiten für
          Abtreibungen. Also hielten sie sich fern voneinander und starben
          irgendwann.
        </span>
        <br />
        <br />
        <span>
          Jakob war einer, der mochte die Arbeit mit den Pflanzen erst. Irgendwann
          hatte aber auch er die Schnauze voll davon. Er starb mit 51. Gerda und
          Louis, tot mit 67 und 42. Robert, 59. Philipp, 2. Tina, 23. Heiko, 62.
        </span>
        <br />
        <br />
        <span>
          Nach spätestens ein paar Generationen fragt man sich doch nicht mehr, ob
          man selbst erleben wird, dass die Oberfläche wieder bewohnbar wird. Es
          wirkt einfach zu unwahrscheinlich, diese Hoffnung überhaupt zu nähren.
          Man lebt noch – aber was ist das für ein Leben? Welchen Anhaltspunkt hat
          man, dass die Oberfläche überhaupt wieder mal bewohnbar ist, wenn sich
          nach Generationen nichts geändert hat? Was ist so wichtig daran, dass
          die Menschheit diese Katastrophe überlebt? Hat das Universum was daran?
          Spielt’s fürs Universum ne Rolle? Was bringt es, die Menschheit ewig
          leben zu lassen, wenn nur als Zahnräder ihrer selbst erhaltenden
          Maschine? Foltert man die Nachkömmlinge nicht, wenn man sie trotz ihres
          Bewusstseins so verwendet, als hätten sie keines? Ist es nicht eh eine
          Frage der Zeit, bis die Menschheit ausstirbt, wieso muss man das so
          lange verzögern, wenn darin so viel Leid steckt?
        </span>
        <br />
        <br />
        <span>
          Das dachten sich Ina, Oscar, Stephanie, Fabi, Myriam, Jonathan, Lukas
          und Susanne. Sie wollten dem ein Ende setzen. Sie hörten auf zu arbeiten
          und erlebten zum ersten Mal in ihrem Leben und für die Menschen im
          Bunker überhaupt: Freizeit. Die Möglichkeit, das Handeln nach eigenen
          Bedürfnissen zu ordnen und nicht nach denen des gemeinsamen Überlebens
          wirkte befreiend. Das Leben, dass sie dann gemeinsam lebten, war kurz,
          ja. Aber es hat sich niemand geschämt, statt zu arbeiten, das zu tun,
          das ihnen im Blut lag: Zu tanzen. Zu singen. Zu basteln. Zu spielen. Zu
          lieben. Zu leben. Zu sterben.
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name18"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'LebenStattUeberleben',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Leben statt Überleben',
    meta: [
      {
        name: 'description',
        content:
          '"Nach der Katastrophe waren wir noch ein paar hundert Menschen im Bunker. Ursprünglich dachten wir, wir könnten irgendwann wieder raus an die Oberfläche, aber nein, wir mussten nach Monaten und [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Leben statt Überleben',
      },
      {
        property: 'og:description',
        content:
          '"Nach der Katastrophe waren wir noch ein paar hundert Menschen im Bunker. Ursprünglich dachten wir, wir könnten irgendwann wieder raus an die Oberfläche, aber nein, wir mussten nach Monaten und [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.leben-statt-ueberleben-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.leben-statt-ueberleben-download-button {
  text-decoration: none;
}
</style>
