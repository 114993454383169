<template>
  <div class="kurzfilme-container">
    <app-navigation rootClassName="navigation-root-class-name42"></app-navigation>
    <h1 class="page-title">Kurzfilme</h1>
    <div class="text-body">
      <span>
        <span>
          Hermann hat derzeit mehrere Drehbücher parallel in Planung für kommende
          Kurzfilmprojekte. Wenn Du Interesse hast mitzumachen als schauspielende
          Person oder Statist, bei der Bedienung der Kamera, Lichter und/oder dem
          Mikrofon oder dem Make-up der Schauspielenden kannst Du Dich gerne bei ihm
          melden.
        </span>
        <br />
        <br />
        <span>
          Zusätzliche Kurzfilme von Jan Appel, einem Freund von Hermann, und weiteren Künstlern sind zu finden auf deren Webseite:
        </span>
        <a
            href="https://visuation.ch"
            target="_blank"
            rel="noreferrer noopener"
            class="card-link"
            style="text-align:center;
            text-decoration:underline;"
          >
            https://visuation.ch
        </a>
      </span>
    </div>
    <div class="card-container">
      <summary-shortfilms
        rootClassName="summary-shortfilms-root-class-name1"
      ></summary-shortfilms>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name30"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import SummaryShortfilms from '../components/summary-shortfilms'
import AppKontakt from '../components/kontakt'

export default {
  name: 'Kurzfilme',
  props: {},
  components: {
    AppNavigation,
    SummaryShortfilms,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Kurzfilme',
    meta: [
      {
        name: 'description',
        content:
          'Hermann hat derzeit mehrere Drehbücher parallel in Planung für kommende Kurzfilmprojekte. Wenn Du Interesse hast, mitzumachen, melde Dich gerne bei ihm.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Kurzfilme',
      },
      {
        property: 'og:description',
        content:
          'Hermann hat derzeit mehrere Drehbücher parallel in Planung für kommende Kurzfilmprojekte. Wenn Du Interesse hast, mitzumachen, melde Dich gerne bei ihm.',
      },
    ],
  },
}
</script>

<style scoped>
.kurzfilme-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
</style>
