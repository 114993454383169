<template>
  <div class="mehr-button-mehr-button more-link">
    <p class="card-link">{{ mehrLink }}</p>
  </div>
</template>

<script>
export default {
  name: 'MehrButton',
  props: {
    mehrLink: {
      type: String,
      default: 'Mehr ->',
    },
  },
}
</script>

<style scoped>
.mehr-button-mehr-button {
  position: relative;
}
.mehr-button-mehr-button:hover {
  opacity: 0.5;
}
</style>
