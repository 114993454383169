<template>
  <div class="them-or-us-container">
    <app-navigation rootClassName="navigation-root-class-name35"></app-navigation>
    <h1 class="page-title">Them or Us</h1>
    <span class="page-subtitle">
      <span>
        Shortstory reinterpretating and combining ideas of two YouTube
        &quot;Kurzgesagt&quot; videos, November 2023
      </span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1LLxOomO2XVjH5_nKyYKEVBx1n-maWMu5/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="them-or-us-download-button button"
    >
      <span class="button-text">As PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          President, we will now brief you on the situation at hand. We have
          located an extraterrestrial intelligent life form on an exoplanet around
          12 light years away. Trying to contact the alien life would take at
          least 12 years with a concentrated beam of radio information in various
          types and formats to hopefully make them understand our peaceful
          intentions. It would then take at least 12 more years to receive an
          answer.
        </span>
        <br />
        <br />
        <span>
          Either way, whether we want to communicate with them or not and whether
          we’re able to or not, they will eventually learn of our existence too.
          Maybe they have already. We cannot exclude the possibility that they are
          going to be hostile towards us. We do not know their technological
          level, but they might be able to build a laser or a similar weapon to
          destroy our species. This might come from a xenophobic intent or because
          they might face a similar dilemma that we do: If we do not strike first,
          they might.
        </span>
        <br />
        <br />
        <span>
          If we allocate all our resources to building a laser now, with the power
          of nuclear fission we might finish one in the following years and fire
          at them. If they take more than 12 and a few years for their laser,
          maybe because they’ll hesitate, they’ll take longer to detect us or
          because they take much longer for their laser, we might annihilate them
          first.
        </span>
        <br />
        <br />
        <span>
          President, you might think of trying diplomacy first, but you must keep
          in mind that this could mean the end of humanity. Think of the
          advantages of communicating with these life forms. If they are worse off
          than us, we have no use of communication except for cataloguing some
          more life forms in this universe. If they are better off than us, they
          have no use of us and can dictate any terms they desire for whatever
          morality they follow.
        </span>
        <br />
        <br />
        <span>
          We have already answered the big question: Aliens do exist. Anything
          additional might be interesting, but is it worth risking humanity? Make
          a wise choice, President.
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name24"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'ThemOrUs',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Them or Us',
    meta: [
      {
        name: 'description',
        content:
          '"President, we will now brief you on the situation at hand. We have located an extraterrestrial intelligent life form on an exoplanet around 12 light [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Them or Us',
      },
      {
        property: 'og:description',
        content:
          '"President, we will now brief you on the situation at hand. We have located an extraterrestrial intelligent life form on an exoplanet around 12 light [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.them-or-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.them-or-us-download-button {
  text-decoration: none;
}
</style>
