<template>
  <div class="letzte-gelegenheit-container">
    <app-navigation rootClassName="navigation-root-class-name"></app-navigation>
    <h1 class="page-title">
      <span>Letzte Gelegenheit</span>
      <br />
    </h1>
    <span class="page-subtitle">
      <span>Haben Häftlinge Platz im Bunker?</span>
      <br />
    </span>
    <iframe
      src="https://www.youtube-nocookie.com/embed/7cH4QuUSUaU"
      class="embedded-video"
    ></iframe>
    <h1 class="uppercase character-title section-head">
      <span>Setting</span>
      <br />
    </h1>
    <div class="letzte-gelegenheit-setting-container text-body">
      <p>
        <span>
          Plötzlich fällt der Strom aus und eine dichte Nebelwand nähert sich von
          Westen. Sirenen und Notfallsender im Radio führen Wehrpflichtige zu
          Sammelpunkten und Zivile in Bunker. So auch das Personal und die
          Häftlinge der Justizvollzugsanstalt Schwarzbach in deren
          Zivilschutzanlage. Zwei Soldat*innen stossen dazu und stellen mit einem
          militärischen Funkgerät den einzigen Kontakt zur Aussenwelt.
        </span>
        <br />
        <br />
        <span>
          Es ist jetzt (bei In-Time) der 10te Tag seit dem Nebel um 14:30 Uhr und
          noch immer ist ungeklärt, was es mit dem Nebel auf sich hat, und gegen
          was das Militär eigentlich kämpft. Die Bunker-Führung versucht
          krampfhaft den Gefängnisalltag beizubehalten, doch Benzin, Essen und
          Medizin gehen aus.
        </span>
        <br />
      </p>
    </div>
    <section class="letzte-gelegenheit-veranstaltungen-kapitel">
      <header class="letzte-gelegenheit-veranstaltungen-titel">
        <h2 class="uppercase section-head">Kommende Runs</h2>
      </header>
      <veranstaltungen-liste></veranstaltungen-liste>
    </section>
    <div class="buttons-container buttons">
      <div class="letzte-gelegenheit-buttons-left buttons">
        <a
          href="https://docs.google.com/document/d/1i-cVj809RqJtO-VaXjUsPew67gtylkIzL_3qc6mI_kE/edit?usp=sharing"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div class="button">
            <span class="button-text">Infos Deutschland</span>
          </div>
        </a>
        <a
          href="https://docs.google.com/document/d/1K8iooPzdVv76UZY8lRtZfj2axTbuK-Z9me2cYAm87bc/edit?usp=sharing"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div class="button"><span class="button-text">Infos Schweiz</span></div>
        </a>
      </div>
      <div class="letzte-gelegenheit-buttons-right buttons">
        <a
          href="https://forms.gle/DU9uUdnHYXPu8rEU8"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div class="button"><span class="button-text">Anmeldung</span></div>
        </a>
        <a
          href="https://discord.gg/eKFCrEZaQ5"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div class="button"><span class="button-text">Discord</span></div>
        </a>
      </div>
    </div>
    <h2 class="uppercase character-title section-head">Charaktere</h2>
    <div class="letzte-gelegenheit-charaktere-container text-body">
      <p>
        <span>
          Die Charaktere sind so geschrieben und verknüpft um intensives,
          dramatisches Spiel zu begünstigen. Alle 12 Spielercharaktere sprechen
          Hochdeutsch und haben einen definierten Nachnamen. Der Vornamen und das
          Geschlecht definiert die spielende Person. Es wird ein paar
          Nichtspielercharaktere geben die von Helfern bespielt werden, zum
          Beispiel Häftling Summers, der*die mit euch ins
          <span v-html="rawut3o"></span>
        </span>
        <span>Spiel startet.</span>
        <br />
        <br />
        <span>
          Alle Charaktere haben einige Geheimnisse und verdeckte Verknüpfungen die
          hier nicht ausgeschrieben sind um die Spielimmersion zu wahren. Die
          folgenden Charakterteaser sollen eine Tendenz aufzeigen, wie sich die
          Charaktere spielen (die Charakterteaser-Bilder sind teilweise
          KI-generiert).
        </span>
        <br />
        <br />
        <span>
          Sobald sich Spielende für alle Spielercharaktere gefunden haben werden
          die vollen Charakterbögen (6 bis 11 A4-Seiten je nach Charakter)
          entsprechend verschickt.
        </span>
        <br />
      </p>
    </div>
    <h2 class="uppercase character-group section-head">Gefängnispersonal</h2>
    <div class="letzte-gelegenheit-character-teaser-row1 character-row">
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/fortier-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Fortier</h2>
            <span class="character-function">
              <span>
                Leitung der Justizvollzugsanstalt
                <span v-html="rawjyj1"></span>
              </span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <br />
        </p>
        <p class="character-themes">Themes: Macht, Gewissensbisse, Ängste</p>
        <p class="character-teaser-text">
          Fortier (gesprochen &quot;Fortjeeh&quot;) übernahm vor 3 Jahren das
          Gefängnis Schwarzbach, nachdem die vorherige Leitung (Zoe Rheineck)
          wegen mutmasslicher Korruption zurücktrat. Kurz nach Fortiers Übernahme
          kam es in der JVA zum Mord des Häftlings Sadowski durch Rodriguez.
        </p>
        <p class="character-teaser-text">
          <span>
            Fortier ist im Bunker weiterhin für das Gefängnispersonal direkt
            zuständig führt den Bunker mit Dr. Bellucci und Wm Sartre.
          </span>
          <br />
        </p>
        <p class="character-teaser-text">
          Fortier ist bekannt dafür tendenziell direkter zu sein als angenehm ist.
          Ausserdem scheint Fortier seit der Zeit im Bunker nicht vor scharfen und
          pragmatischen Massnahmen zurückzuschrecken.
        </p>
      </section>
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/bellucci-200h.png"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">
              <span>Dr. Bellucci</span>
              <br />
            </h2>
            <span class="character-function">
              <span>Psychologische Sachverständigung</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Kindertod</span>
          <br />
        </p>
        <p class="character-themes">
          Themes: Trauma, Manipulation, Suche, Schuld
        </p>
        <p class="character-teaser-text">
          Dr. Bellucci (gesprochen &quot;Beluhtschi&quot;) wurde vor 2 Jahren
          psychologische Sachverständigung im Gefängnis, als Rodriguez Sadowski
          ermordete. Belluccis Aufgabe war langfristige Massnahmen zu entwickeln,
          um dies in Zukunft zu verhindern.
        </p>
        <p class="character-teaser-text">
          Bellucci führt verschiedene Therapien mit den Bunkerinsassen durch um
          diese bei Verstand zu halten. Bellucci führt mit Dr. Jankovic täglich
          Checkups an allen Bewohnern durch, auch gegenseitig. Bellucci berät
          Fortier mit Jankovic.
        </p>
        <p class="character-teaser-text">
          Bellucci ist bekannt dafür mindestens gegen aussen empathisch zu sein.
        </p>
      </section>
    </div>
    <div class="character-row">
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/jankovic-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Dr. Jankovic</h2>
            <span class="character-function">Arzt*Ärztin</span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Zerrissenheit, Liebe, Gewissensbisse</span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Dr. Jankovic (gesprochen &quot;Jankovitsch&quot;) ist der/die
            Gefängnisarzt/-ärztin. Jankovic behandelt
          </span>
          <span>
            das Personal und die Häftlinge medizinisch und macht mit Dr. Bellucci
            tägliche Checkups. Jankovic entscheidet wer welche Medizin erhält, da
            diese limitiert ist.
          </span>
          <span>Ausserdem berät Jankovic Fortier mit Bellucci.</span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Vor drei Jahren wurden Jankovics medizinischen Instrumente von
            Rodriguez manipuliert was durch eine Überdosis Opioide den Häftling
            Sadowski tötete.
          </span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Jankovic ist bekannt dafür freundlich aber, was die Gesundheit der
            Bunkerbewohner angeht, auch sehr streng zu sein.
          </span>
          <br />
        </p>
      </section>
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/krueger-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Krüger</h2>
            <span class="character-function">Wärter*in &amp; Bunkerelektrik</span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Gewissensbisse, Freundschaft, Suche, Sehnsucht</span>
          <span>, Hass</span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Wärter*in Krüger arbeitet seit 4 Jahren im Gefängnis Schwarzbach.
            Krüger ist für die Bunkerelektrik
          </span>
          <span>
            zuständig, weil sich Krüger damit am besten auskennt. Krüger
            instruiert dabei Häftlinge, welche die Arbeit entsprechend erledigen.
          </span>
          <span>
            Krüger spielt ab und zu Karten mit Dr. Jenatsch und Collins.
          </span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            In der Zivilschutzanlage hilft Krüger Dr. Jankovic bei der
            medizinischen Versorgung der Bunkerinsassen.
          </span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Krüger ist bekannt dafür etwas grummelig und rastlos zu sein.
          </span>
          <span>
            Krüger hat hohe Standards was die eigene Arbeit und die Arbeit von
            anderen angeht.
          </span>
          <br />
        </p>
      </section>
    </div>
    <div class="character-row">
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/schmidt-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Schmidt</h2>
            <span class="character-function">
              <span>Wärter*in &amp; Zeugwart</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Paranoia, Macht, Ängste, Verlust, Hass</span>
          <br />
        </p>
        <p class="character-teaser-text">
          Wärter*in Schmidt arbeitet seit 3 Jahren im Gefängnis Schwarzbach.
          Schmidt kam ziemlich zeitgleich mit Fortier zu der Stelle. Schmidt ist
          Zeugwart und damit verantwortlich für die Lagerung und Wartung der
          Materialien, Werkzeuge, Geräte, Medizin und Waffen der JVA. Schmidt
          nutzt die Häftlinge um diese zu warten und zu reinigen. Wenn Krüger mit
          Häftlingen etwas an der Elektrik arbeiten möchte braucht Krüger die
          Werkzeuge von Schmidt.
        </p>
        <p class="character-teaser-text">
          Schmidt ist bekannt dafür, unangenehme Fragen zu stellen und geht eher
          grob mit den Häftlingen um. Dafür hat niemand die Aufgaben des Zeugwarts
          besser im Griff als Schmidt.
        </p>
      </section>
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/hagiro-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Hagiro</h2>
            <span class="character-function">
              <span>dienstälteste*r Wärter*in</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <span>, Drogen</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Gerechtigkeit, Gewalt, Druck, Hass</span>
          <br />
        </p>
        <p class="character-teaser-text">
          Hagiro ist mit 7 Jahren Dienst von den Wärter*innen am längsten im
          Gefängnis Schwarzbach. Hagiro setzt die Massnahmen von Fortier meist
          rigoros durch, zögert aber auch nicht die Meinung zu äussern, wenn
          Hagiro etwas missfällt. Hagiro hilft im Bunker Fortier bei der
          Aktenverwaltung und hat Häftling Lindbeck dafür als Unterstützung.
        </p>
        <p class="character-teaser-text">
          Hagiro ist bekannt für einen konsequenten Gerechtigkeitssinn und für
          einen Raucherhusten. Hagiro ist streng zu den Häftlingen und freundlich
          zu den anderen.
        </p>
      </section>
    </div>
    <h2 class="uppercase character-group section-head">Häftlinge</h2>
    <div class="character-row">
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/rodriguez-200h.png"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Rodriguez</h2>
            <span class="character-function">
              <span>Häftling</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod, Drogen</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Unterdrückung, Sehnsucht, Suche, Gewissensbisse</span>
          <br />
        </p>
        <p class="character-teaser-text">
          Rodriguez (gesprochen &quot;Rodrigess&quot;) sitzt bereits seit 6 Jahren
          im Gefängnis Schwarzbach und damit von den anwesenden Häftlingen am
          längsten. Eigentlich hätte Rodriguez letztes Jahr entlassen werden
          sollen, doch vor 2 Jahren brachte Rodriguez den Häftling Sadowski
          (gesprochen &quot;Sadofski&quot;) um, indem Rodriguez die medizinischen
          Instrumente von Jankovic manipulierte. Deswegen sitzte Rodriguez 3 Jahre
          lang in Isolationshaft bis die Sirenen losheulten.
        </p>
        <p class="character-teaser-text">
          Rodriguez war vor dem Gefängnis Handwerker/in und arbeitete auch 3 Jahre
          in der Gefängnisschreinerei. Rodriguez ist daher besonders geeignet,
          mechanische Apparaturen zu reparieren.
        </p>
        <p class="character-teaser-text">
          Rodriguez ist bekannt für eine raue aber zuvorkommende Art. Wegen dem
          Mord vor drei Jahren verlor Rodriguez aber bei den meisten das
          Vertrauen.
        </p>
      </section>
      <section class="letzte-gelegenheit-jenatsch character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/jenatsch-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Prof. Dr. Jenatsch</h2>
            <span class="character-function">
              <span>Häftling</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Verzweiflung, Druck, Commitment, Einsamkeit</span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Prof. Dr. Jenatsch ist der neuste Häftling und kam erst eine Woche vor
            der Katastrophe ins Gefängnis Schwarzbach. Jenatsch war zwei Monate
            vor der Katastrophe im Fernsehen zu sehen, wie Jenatsch den Parteichef
            der &quot;Silbernen Partei&quot; erschossen hatte.
          </span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Wenn Jenatsch grade nichts anderes macht, schreibt Jenatsch Formeln,
            Tabellen und Graphen auf Notizzettel. Die Wärter mussten Jenatsch
            schon mehrmals dafür bestrafen, als Jenatsch diese trotz Warnungen
            überall wieder liegen liess.
            <span v-html="rawln6l"></span>
          </span>
          <span>Ab und zu spielt Jenatsch Karten mit Collins und Krüger.</span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Jenatsch ist bekannt für eine chaotische, pragmatische und schnelle
            Vorgehensweise. Wenn Jenatsch sich etwas in den Kopf setzt haben die
            Wärter Mühe Jenatsch zu bändigen.
          </span>
          <br />
        </p>
      </section>
    </div>
    <div class="character-row">
      <section class="letzte-gelegenheit-collins character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/collins-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Collins</h2>
            <span class="character-function">
              <span>Häftling</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <span>, Drogen</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Ungerechtigkeit, Pflicht, Gewissensbisse, Familie</span>
          <br />
        </p>
        <p class="character-teaser-text">
          Collins ist seit 5 Jahren im Gefängnis. Bevor alle im Bunker waren hörte
          man selten was von Collins. Collins war nicht jemand, der Krawall
          machte. Doch seit sie im Bunker sind, hat Collins immer wieder Ärger mit
          den Wärtern. Collins bezweifelt, dass es Zufall ist, dass Collins die
          meisten anstrengenden Aufgaben übernehmen muss. Collins war nach eigenen
          Berechnungen schon mindestens fünf mal länger an der Lüftungskurbel als
          alle anderen.
        </p>
        <p class="character-teaser-text">
          Collins ist bekannt für eine reservierte Art und eine kurze Lunte wenn
          Collins provoziert wird. Ansonsten schluckt Collins die
          Unannehmlichkeiten im Bunker runter und spielt Karten mit Krüger und
          Prof. Dr. Jenatsch.
        </p>
      </section>
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/lindbeck-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">
              Lindbeck
              <span v-html="rawwodk"></span>
            </h2>
            <span class="character-function">
              <span>Häftling</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod, Sexualität zwischen Schüler und Lehrer</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Gewissensbisse, Suche, Sehnsucht, Verzweiflung</span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Lindbeck sitzt seit 2 Jahren im Gefängnis Schwarzbach. Lindbeck war
            die einzige
          </span>
          <span>
            Person, welche trotz der Eile bei der Katastrophe Bücher in den Bunker
            mitbrachte. Während den 2 Jahren im Gefängnis arbeitete Lindbeck im
            Postbüro. Im Bunker schrieb Lindbeck einige Literatur nieder, an die
            Lindbeck sich noch erinnern konnte bevor alle in den Bunker gingen.
            Lindbeck liest davon manchmal vor und hat auch schon zu speziellen
            Anlässen wie Geburtstage ein Lied angestimmt.
          </span>
          <br />
        </p>
        <p class="character-teaser-text">
          <span>
            Lindbeck ist bekannt dafür, eine eher traurige Stimmung zu haben.
          </span>
          <br />
        </p>
      </section>
    </div>
    <h2 class="uppercase character-group section-head">Militär</h2>
    <div class="character-row">
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/sartre-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">Wm Sartre</h2>
            <span class="character-function">
              <span>Gruppenführer*in</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod, Drogen</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Familie, Sucht, Scham, Schutz</span>
          <br />
        </p>
        <p class="character-teaser-text">
          Wm (Wachtmeister [militärische Ränge sind Geschlechtsneutral], das ist
          ein Unteroffiziersrang) Sartre kam mit Pavone in den Bunker. Sartre
          leitet Pavone an und integriert sich und Pavone auch bei dem Rest der
          Bunkerbesatzung. Sartre bespricht sich gelegentlich mit Fortier.
        </p>
        <p class="character-teaser-text">
          Sartre ist bekannt dafür, eine besorgte Mimik zu haben. Sartres
          Redensweise ist trotzdem befehlerisch.
        </p>
        <p class="letzte-gelegenheit-paragraph36 character-teaser-text">
          (Nur ein paar Charaktere wissen mehr zu diesem Charakter, entsprechend
          steht hier wenig.)
        </p>
      </section>
      <section class="character-teaser-container">
        <div class="character-header">
          <img
            alt="Symbolbild"
            src="/letzte-gelegenheit/charaktere/pavone-200h.webp"
            class="character-image"
          />
          <div class="character-description">
            <h2 class="character-name">
              <span>Sdt Pavone</span>
              <br />
            </h2>
            <span class="character-function">
              <span>Funker*in</span>
              <br />
            </span>
          </div>
          <div></div>
        </div>
        <p class="character-trigger">
          <span>OT-Trigger: Tod</span>
          <br />
        </p>
        <p class="character-themes">
          <span>Themes: Ängste, Gewissensbisse, Verzweiflung</span>
          <br />
        </p>
        <p class="character-teaser-text">
          Sdt (Soldat [militärische Ränge sind Geschlechtsneutral]) Pavone brachte
          das militärische Funkgerät in den Bunker, das Pavone mit Sartre bedient.
          Pavone bittet immer wieder Häftlinge um bei der Bedienung des Funkgeräts
          zu helfen.
        </p>
        <p class="character-teaser-text">
          Pavone wirkt unruhig, hat aber eine freundliche Art und bestechenden
          Charme.
        </p>
        <p class="letzte-gelegenheit-paragraph37 character-teaser-text">
          (Nur ein paar Charaktere wissen mehr zu diesem Charakter, entsprechend
          steht hier wenig.)
        </p>
      </section>
    </div>
    <h2 class="uppercase character-title section-head">Unterstützung</h2>
    <div class="letzte-gelegenheit-unterstuetzung-container text-body">
      <p>
        <span>
          Konzipiert und organisiert wurde und wird das gesamte LARP von Hermann.
          Bei Runs 3, 4, 5 und 6 haben Robin Plötzwich und Annabelle Bitzmann beim
          Auf- und Abbau und als Spielleitung geholfen und Sydney Mikosch bei Run
          4 bei der Verpflegung. Weitere Helfende haben selbst zusätzliche
          Funksprüche für den Militärfunk geschrieben und/oder vertont. Diese sind
          hier (noch) nicht gelistet um die Immersion während dem Spiel zu wahren.
        </span>
        <br />
        <br />
        <span>
          Spielende, die ein Sponsorenticket gekauft haben um Solidaritätstickets
          zu ermöglichen und Defizite auszugleichen:
        </span>
        <br />
        <span>- André Röthemeier</span>
        <br />
        <span>- Anke</span>
        <br />
        <span>- Fabian</span>
        <br />
        <span>- Josha</span>
        <br />
        <span>- Rikki</span>
        <br />
        <span>- Tim Losch</span>
        <br />
        <br />
        <span>
          NSC/Helfende, die bei Pay-what-you-Want von Runs 1 bis 4 etwas gezahlt
          haben:
        </span>
        <br />
        <span>- Björn</span>
        <br />
        <span>- Florian H.</span>
        <br />
        <span>- Jan R.</span>
        <br />
        <span>- Mai</span>
        <br />
        <span>- PeFox</span>
        <br />
        <span>
          -
          <span v-html="rawaga9"></span>
        </span>
        <span>Phil U.</span>
        <br />
        <span>- Sue</span>
        <br />
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name2"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import VeranstaltungenListe from '../../components/veranstaltungen-liste'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'LetzteGelegenheit',
  props: {},
  components: {
    AppNavigation,
    VeranstaltungenListe,
    AppKontakt,
  },
  data() {
    return {
      rawut3o: ' ',
      rawjyj1: ' ',
      rawln6l: ' ',
      rawwodk: ' ',
      rawaga9: ' ',
    }
  },
  metaInfo: {
    title: 'OpenVisor - Letzte Gelegenheit',
    meta: [
      {
        name: 'description',
        content:
          'Als vor 9 Tagen die Sirenen häulten suchte das Gefängnis Schwarzbach Zuflucht im Bunker. Langsam gehen die Ressourcen aus und die Lage verschärft sich...',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Letzte Gelegenheit',
      },
      {
        property: 'og:description',
        content:
          'Als vor 9 Tagen die Sirenen häulten suchte das Gefängnis Schwarzbach Zuflucht im Bunker. Langsam gehen die Ressourcen aus und die Lage verschärft sich...',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/f7f91f87-097b-4cfb-a31b-ef768130e437/c97eb9ef-8ad2-484c-ac83-73a36202b4f3?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.letzte-gelegenheit-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
.letzte-gelegenheit-setting-container {
  width: 95%;
}
.letzte-gelegenheit-veranstaltungen-kapitel {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.letzte-gelegenheit-veranstaltungen-titel {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.letzte-gelegenheit-buttons-left {
  width: 45%;
}
.letzte-gelegenheit-buttons-right {
  gap: 12%;
}
.letzte-gelegenheit-charaktere-container {
  width: 95%;
}
.letzte-gelegenheit-character-teaser-row1 {
  position: relative;
}
.letzte-gelegenheit-jenatsch {
  position: relative;
}
.letzte-gelegenheit-collins {
  position: relative;
}
.letzte-gelegenheit-paragraph36 {
  font-style: italic;
}
.letzte-gelegenheit-paragraph37 {
  font-style: italic;
}
.letzte-gelegenheit-unterstuetzung-container {
  width: 95%;
}
@media(max-width: 991px) {
  .letzte-gelegenheit-buttons-right {
    margin-top: -8%;
  }
}
@media(max-width: 767px) {
  .letzte-gelegenheit-veranstaltungen-kapitel {
    border-color: transparent;
  }
}
@media(max-width: 479px) {
  .letzte-gelegenheit-veranstaltungen-titel {
    width: auto;
    height: auto;
    max-width: auto;
    padding-top: 0px;
  }
}
</style>
