<template>
  <div class="conundrum-schatten-ueber-ostmerkopa-container">
    <app-navigation rootClassName="navigation-root-class-name40"></app-navigation>
    <span class="page-title">Conundrum: Schatten über Ostmerkopa</span>
    <span class="page-subtitle">
      <span>Nordic LARP, März 2025</span>
      <br />
    </span>
    <div class="text-body">
      <p>
        <span>
          Hermann stellt mit Robin die Headorga von &quot;Conundrum: Schatten über
          Ostmerkopa&quot;.
        </span>
        <br />
      </p>
    </div>
    <a
      href="https://notgreatnotterrible.de"
      target="_blank"
      rel="noreferrer noopener"
      class="conundrum-schatten-ueber-ostmerkopa-link"
    >
      <div class="conundrum-schatten-ueber-ostmerkopa-plot button">
        <span class="button-text">
          <span>Infos (Not Great Not Terrible Webseite)</span>
          <br />
        </span>
      </div>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name28"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'ConundrumSchattenUeberOstmerkopa',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Conundrum: Schatten über Ostmerkopa',
    meta: [
      {
        name: 'description',
        content:
          'Hermann stellt mit Robin die Headorga von "Conundrum: Schatten über Ostmerkopa": https://notgreatnotterrible.de',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Conundrum: Schatten über Ostmerkopa',
      },
      {
        property: 'og:description',
        content:
          'Hermann stellt mit Robin die Headorga von "Conundrum: Schatten über Ostmerkopa": https://notgreatnotterrible.de',
      },
    ],
  },
}
</script>

<style scoped>
.conundrum-schatten-ueber-ostmerkopa-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
}
.conundrum-schatten-ueber-ostmerkopa-link {
  display: contents;
}
.conundrum-schatten-ueber-ostmerkopa-plot {
  text-decoration: none;
}
</style>
