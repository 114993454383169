import { render, staticRenderFns } from "./ich-will-kein-hammer-sein.vue?vue&type=template&id=3ddcef14&scoped=true"
import script from "./ich-will-kein-hammer-sein.vue?vue&type=script&lang=js"
export * from "./ich-will-kein-hammer-sein.vue?vue&type=script&lang=js"
import style0 from "./ich-will-kein-hammer-sein.vue?vue&type=style&index=0&id=3ddcef14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddcef14",
  null
  
)

export default component.exports