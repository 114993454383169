<template>
  <footer class="kontakt-footer" v-bind:class="rootClassName">
    <div class="kontakt-impressum">
      <span class="kontakt-impressum1">{{ impressum }}</span>
      <a href="mailto:info@openvisor.ch?subject=" class="normal-text">
        {{ name }}
      </a>
      <a href="mailto:info@openvisor.ch?subject=" class="normal-text">
        {{ adresse }}
      </a>
      <a href="mailto:info@openvisor.ch?subject=" class="normal-text">
        {{ ort }}
      </a>
      <a href="mailto:info@openvisor.ch?subject=" class="normal-text">
        {{ land }}
      </a>
      <a href="mailto:info@openvisor.ch?subject=" class="kontakt-email">
        {{ email }}
      </a>
      <span class="kontakt-website">Website</span>
      <a href="mailto:info@openvisor.ch?subject=" class="normal-text">
        Hosted in Switzerland
      </a>
      <a href="mailto:info@openvisor.ch?subject=" class="normal-text">
        <span>
          Created with
          <span v-html="rawpirw"></span>
        </span>
        <span>teleporthq.io</span>
      </a>
      <a
        href="https://git.openvisor.ch/OpenVisor/OpenVisor-Website"
        target="_blank"
        rel="noreferrer noopener"
        class="kontakt-source-code"
      >
        Source Code
      </a>
    </div>
    <iframe
      src="https://dashboard.mailerlite.com/forms/582593/98301649820321679/share"
      class="kontakt-newsletter-form"
    ></iframe>
  </footer>
</template>

<script>
export default {
  name: 'Kontakt',
  props: {
    impressum: {
      type: String,
      default: 'Impressum',
    },
    land: {
      type: String,
      default: 'Schweiz',
    },
    rootClassName: String,
    email: {
      type: String,
      default: 'info@openvisor.ch',
    },
    adresse: {
      type: String,
      default: 'Mülihalde 21',
    },
    ort: {
      type: String,
      default: '8484 Weisslingen',
    },
    name: {
      type: String,
      default: 'Hermann Eichhorn',
    },
  },
  data() {
    return {
      rawpirw: ' ',
    }
  },
}
</script>

<style scoped>
.kontakt-footer {
  gap: 20%;
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 10%;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: 10%;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
}
.kontakt-impressum {
  flex: 0 0 auto;
  width: 230px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-impressum1 {
  color: #ffffff;
  font-size: 36px;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
}
.kontakt-email {
  color: #ffff00;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}
.kontakt-website {
  color: rgb(255, 255, 255);
  font-size: 36px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  text-transform: uppercase;
}
.kontakt-source-code {
  color: rgb(255, 255, 0);
  font-size: 18px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}
.kontakt-newsletter-form {
  width: 320px;
  height: 360px;
  margin-bottom: var(--dl-space-space-twounits);
}




































@media(max-width: 767px) {
  .kontakt-footer {
    flex-direction: column;
  }
  .kontakt-impressum {
    margin-bottom: var(--dl-space-space-fourunits);
  }
}
</style>
