<template>
  <div class="page-container">
    <app-navigation rootClassName="navigation-root-class-name39"></app-navigation>
    <img
      alt="OpenVisor"
      src="/openvisor_logo_only_text_compressed-200h.png"
      class="page-image"
    />
    <span class="page-subtitle">404 (Seite nicht gefunden)</span>
    <div class="text-body">
      <span>
        Die URL führt zu keiner aktiven Seite. Bitte benutze das Menü um zu einer
        Seite Deiner Wahl zu gelangen.
      </span>
    </div>
    <app-kontakt></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import AppKontakt from '../components/kontakt'

export default {
  name: '404 (Seite nicht gefunden)',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - 404 (Seite nicht gefunden)',
    meta: [
      {
        name: 'description',
        content: 'Die URL führt zu keiner aktiven Seite.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - 404 (Seite nicht gefunden)',
      },
      {
        property: 'og:description',
        content: 'Die URL führt zu keiner aktiven Seite.',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
}
.page-image {
  width: 640px;
}
@media(max-width: 991px) {
  .page-image {
    width: 420px;
  }
}
@media(max-width: 767px) {
  .page-image {
    margin-top: var(--dl-space-space-unit);
  }
}
</style>
