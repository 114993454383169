<template>
  <div class="getraeumtes-glueck-container">
    <app-navigation rootClassName="navigation-root-class-name37"></app-navigation>
    <h1 class="page-title">Geträumtes Glück</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Liebe, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1uSdNFezdhoXgtXFo-pKTFC0FBw2jFuvN/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="getraeumtes-glueck-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>“Ich habe mich verliebt…”</span>
        <br />
        <br />
        <span>“Was? Das ist doch grossartig?”</span>
        <br />
        <br />
        <span>“Ich weiss nicht… Sie war in meinem Traum…”</span>
        <br />
        <br />
        <span>“Oh… Schade…”</span>
        <br />
        <br />
        <span>
          “Wieso eigentlich schade? Ich träume nicht das erste Mal von ihr. Fast
          in jedem Traum sehe ich sie wieder und wir machen jeweils dort weiter,
          wo wir aufgehört haben. Es ist perfekt…”
        </span>
        <br />
        <br />
        <span>“Aber das ist nicht echt. Sie existiert nicht.”</span>
        <br />
        <br />
        <span>“Wenn ich schlafe tut sie’s.”</span>
        <br />
        <br />
        <span>“Das ist aber nicht real.”</span>
        <br />
        <br />
        <span>
          “Na und? Wenn ich weiss, dass ich da wiederkehre, kann ich mich nicht
          einfach entscheiden, dass deine Realität ein Traum ist und mein Traum
          die Realität?”
        </span>
        <br />
        <br />
        <span>
          “Ich schätze, das kannst du. Aber ist es nicht seltsam? Du hast sie
          geschaffen. Also, dein Unterbewusstsein. Das ist doch nicht richtig.”
        </span>
        <br />
        <br />
        <span>
          “Wieso sollte es das nicht sein? Muss mein Glück von jemand anderem
          abhängen, damit es richtig ist? Das bringt doch niemandem was.”
        </span>
        <br />
        <br />
        <span>“Aber… Was ist mit dieser Welt. Was ist mit uns?”</span>
        <br />
        <br />
        <span>
          “Wenn du ohne etwas getan hast, in ein Gefängnis geworfen wirst, bist du
          dann verpflichtet, ein Häftling zu sein, nur weil andere das sein
          wollen?”
        </span>
        <br />
        <br />
        <span>
          “Wie soll das überhaupt funktionieren? Lebst du nur noch, um schlafen zu
          können?”
        </span>
        <br />
        <br />
        <span>
          “Wieso nicht? Arbeit ist Arbeit. Mit meiner Freizeit kann ich doch tun,
          was ich will. Wenn ich mich dann in eine Welt teleportieren kann, in der
          ich mich wohlfühle, schade ich ja niemandem.”
        </span>
        <br />
        <br />
        <span>“Ist das jetzt ein Abschied?”</span>
        <br />
        <br />
        <span>
          “Vielleicht. Du warst auch schon in meinen Träumen. Ich erinnere mich an
          dich. Klar bist das nicht du, aber ein Abschied ist es ja nur, wenn wir
          aufhören aneinander zu denken. Du hast mir doch immer von deiner Angst
          erzählt, im Schlaf zu sterben. Nie zu wissen, ob wenn man schlafen geht,
          man wieder aufwacht. Sieh es so: Wenn ich träume, kann ich dabei meine
          letzten Momente wach erleben. Und vielleicht bist du dann sogar auch
          dabei. Für mich mindestens.”
        </span>
        <br />
        <br />
        <span>“Das stimmt mich irgendwie trotzdem traurig…”</span>
        <br />
        <br />
        <span>“Das tut mir Leid. Mich macht es glücklich.”</span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name26"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'GetraeumtesGlueck',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Geträumtes Glück',
    meta: [
      {
        name: 'description',
        content:
          '“Ich habe mich verliebt…”\n“Was? Das ist doch grossartig?”\n“Ich weiss nicht… Sie war in meinem Traum…”\n“Oh… Schade…”\n“Wieso eigentlich schade? Ich träume [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Geträumtes Glück',
      },
      {
        property: 'og:description',
        content:
          '“Ich habe mich verliebt…”\n“Was? Das ist doch grossartig?”\n“Ich weiss nicht… Sie war in meinem Traum…”\n“Oh… Schade…”\n“Wieso eigentlich schade? Ich träume [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.getraeumtes-glueck-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.getraeumtes-glueck-download-button {
  text-decoration: none;
}
</style>
