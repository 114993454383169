import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import KlonOderLeid from './views/kurzgeschichten/klon-oder-leid'
import Games from './views/games'
import HuhnRammtHuhn from './views/kurzgeschichten/huhn-rammt-huhn'
import HeiligerSteinkrieg from './views/kurzgeschichten/heiliger-steinkrieg'
import DieLegendeVomWolfDesSuedens from './views/kurzfilme/die-legende-vom-wolf-des-suedens'
import FunktioniertLARPAuchOnline from './views/blog/funktioniert-larp-auch-online'
import VersetztesBeduerfnis from './views/kurzgeschichten/versetztes-beduerfnis'
import Assemblatron from './views/games/assemblatron'
import EinsamesSchaf from './views/kurzgeschichten/einsames-schaf'
import WiesoItrasByEinSoSimplesWieGrandiosesPenAndPenIst from './views/blog/wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist'
import DieLoesungGegenLeid from './views/kurzgeschichten/die-loesung-gegen-leid'
import GefangenerGorilla from './views/kurzgeschichten/gefangener-gorilla'
import ThemOrUs from './views/kurzgeschichten/them-or-us'
import GetraeumtesGlueck from './views/kurzgeschichten/getraeumtes-glueck'
import Blog from './views/blog'
import WasUnterscheidetHimmelVonHoelle from './views/kurzgeschichten/was-unterscheidet-himmel-von-hoelle'
import ConundrumSchattenUeberOstmerkopa from './views/larps/conundrum-schatten-ueber-ostmerkopa'
import MurphysLARP from './views/games/murphys-larp'
import LasstMichRuhen from './views/kurzgeschichten/lasst-mich-ruhen'
import Kurzgeschichten from './views/kurzgeschichten'
import InfiniteDeaths from './views/kurzgeschichten/infinite-deaths'
import LebenStattUeberleben from './views/kurzgeschichten/leben-statt-ueberleben'
import WieTalesInsideEineEinmaligeImmersionErreicht from './views/blog/wie-tales-inside-eine-einmalige-immersion-erreicht'
import SuperkraftImStarbucks from './views/kurzgeschichten/superkraft-im-starbucks'
import UnwichtigsterMensch from './views/kurzgeschichten/unwichtigster-mensch'
import TeilungZweierWelten from './views/kurzgeschichten/teilung-zweier-welten'
import WieManEinGutesOnlineLARPMachtUndWieNicht from './views/blog/wie-man-ein-gutes-online-larp-macht-und-wie-nicht'
import LARPs from './views/larps'
import IchWillKeinHammerSein from './views/kurzgeschichten/ich-will-kein-hammer-sein'
import Kurzfilme from './views/kurzfilme'
import SuesseTraeume from './views/larps/suesse-traeume'
import UeberUns from './views/ueber-uns'
import Larpkonzept from './views/larpkonzept'
import EinOrdenFrEineGuteTat from './views/kurzgeschichten/ein-orden-fuer-eine-gute-tat'
import LetzteGelegenheit from './views/larps/letzte-gelegenheit'
import Rosenteufel from './views/kurzgeschichten/rosenteufel'
import Home from './views/home'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'klon-oder-leid',
      path: '/kurzgeschichten/klon-oder-leid',
      component: KlonOderLeid,
    },
    {
      name: 'games',
      path: '/games',
      component: Games,
    },
    {
      name: 'huhn-rammt-huhn',
      path: '/kurzgeschichten/huhn-rammt-huhn',
      component: HuhnRammtHuhn,
    },
    {
      name: 'heiliger-steinkrieg',
      path: '/kurzgeschichten/heiliger-steinkrieg',
      component: HeiligerSteinkrieg,
    },
    {
      name: 'die-legende-vom-wolf-des-suedens',
      path: '/kurzfilme/wolf-des-suedens',
      component: DieLegendeVomWolfDesSuedens,
    },
    {
      name: 'funktioniert-larp-auch-online',
      path: '/blog/funktioniert-larp-auch-online',
      component: FunktioniertLARPAuchOnline,
    },
    {
      name: 'versetztes-beduerfnis',
      path: '/kurzgeschichten/versetztes-beduerfnis',
      component: VersetztesBeduerfnis,
    },
    {
      name: 'assemblatron',
      path: '/games/assemblatron',
      component: Assemblatron,
    },
    {
      name: 'einsames-schaf',
      path: '/kurzgeschichten/einsames-schaf',
      component: EinsamesSchaf,
    },
    {
      name: 'wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist',
      path: '/blog/wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist',
      component: WiesoItrasByEinSoSimplesWieGrandiosesPenAndPenIst,
    },
    {
      name: 'die-loesung-gegen-leid',
      path: '/kurzgeschichten/die-loesung-gegen-leid',
      component: DieLoesungGegenLeid,
    },
    {
      name: 'gefangener-gorilla',
      path: '/kurzgeschichten/gefangener-gorilla',
      component: GefangenerGorilla,
    },
    {
      name: 'them-or-us',
      path: '/kurzgeschichten/them-or-us',
      component: ThemOrUs,
    },
    {
      name: 'getraeumtes-glueck',
      path: '/kurzgeschichten/getraeumtes-glueck',
      component: GetraeumtesGlueck,
    },
    {
      name: 'blog',
      path: '/blog',
      component: Blog,
    },
    {
      name: 'was-unterscheidet-himmel-von-hoelle',
      path: '/kurzgeschichten/was-unterscheidet-himmel-von-hoelle',
      component: WasUnterscheidetHimmelVonHoelle,
    },
    {
      name: 'conundrum-schatten-ueber-ostmerkopa',
      path: '/larps/conundrum-schatten-ueber-ostmerkopa',
      component: ConundrumSchattenUeberOstmerkopa,
    },
    {
      name: 'murphys-larp',
      path: '/games/murphys-larp',
      component: MurphysLARP,
    },
    {
      name: 'lasst-mich-ruhen',
      path: '/kurzgeschichten/lasst-mich-ruhen',
      component: LasstMichRuhen,
    },
    {
      name: 'kurzgeschichten',
      path: '/kurzgeschichten',
      component: Kurzgeschichten,
    },
    {
      name: 'infinite-deaths',
      path: '/kurzgeschichten/infinite-deaths',
      component: InfiniteDeaths,
    },
    {
      name: 'leben-statt-ueberleben',
      path: '/kurzgeschichten/leben-statt-ueberleben',
      component: LebenStattUeberleben,
    },
    {
      name: 'wie-tales-inside-eine-einmalige-immersion-erreicht',
      path: '/blog/wie-tales-inside-eine-einmalige-immersion-erreicht',
      component: WieTalesInsideEineEinmaligeImmersionErreicht,
    },
    {
      name: 'superkraft-im-starbucks',
      path: '/kurzgeschichten/superkraft-im-starbucks',
      component: SuperkraftImStarbucks,
    },
    {
      name: 'unwichtigster-mensch',
      path: '/kurzgeschichten/unwichtigster-mensch',
      component: UnwichtigsterMensch,
    },
    {
      name: 'teilung-zweier-welten',
      path: '/kurzgeschichten/teilung-zweier-welten',
      component: TeilungZweierWelten,
    },
    {
      name: 'wie-man-ein-gutes-online-larp-macht-und-wie-nicht',
      path: '/blog/wie-man-ein-gutes-online-larp-macht-und-wie-nicht',
      component: WieManEinGutesOnlineLARPMachtUndWieNicht,
    },
    {
      name: 'larps',
      path: '/larps',
      component: LARPs,
    },
    {
      name: 'ich-will-kein-hammer-sein',
      path: '/kurzgeschichten/ich-will-kein-hammer-sein',
      component: IchWillKeinHammerSein,
    },
    {
      name: 'kurzfilme',
      path: '/kurzfilme',
      component: Kurzfilme,
    },
    {
      name: 'suesse-traeume',
      path: '/larps/suesse-traeume',
      component: SuesseTraeume,
    },
    {
      name: 'ueber-uns',
      path: '/ueber-uns',
      component: UeberUns,
    },
    {
      name: 'larpkonzept',
      path: '/larpkonzept',
      component: Larpkonzept,
    },
    {
      name: 'ein-orden-fuer-eine-gute-tat',
      path: '/kurzgeschichten/ein-orden-fuer-eine-gute-tat',
      component: EinOrdenFrEineGuteTat,
    },
    {
      name: 'letzte-gelegenheit',
      path: '/larps/letzte-gelegenheit',
      component: LetzteGelegenheit,
    },
    {
      name: 'rosenteufel',
      path: '/kurzgeschichten/rosenteufel',
      component: Rosenteufel,
    },
    {
      name: 'home',
      path: '/',
      component: Home,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // Scroll to top left on route change
    return { x: 0, y: 0 };
  },
})
