<template>
  <main class="card-rows-container" v-bind:class="rootClassName">
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Ein Orden für eine gute Tat</h3>
        <p class="card-text">
          <span>
            &quot;Ein brauner Schneehase besucht ein ein Rothörnchen und ein
            Grauhörnchen im vom Herbst bunt gefärbten Wald. Er hat einen Orden,
            den er für eine gute Tat vergeben möchte. Doch welches Hörnchen
            schafft es, diesen zu bekommen?&quot;
          </span>
          <br />
          <br />
          <span>
            Diese Kurzgeschichte für Kinder und Eltern stellt spielerisch eine
            moralische Frage dar, dessen Antwort das Elternteil oder das Kind
            selbst finden muss. Es soll das Kind unterhalten und zugleich mit
            einer einfachen aber doch spannenden Fragestellung bilden.
          </span>
        </p>
        <router-link to="/kurzgeschichten/ein-orden-fuer-eine-gute-tat">
          <app-lesen class="summary-shortstories-component"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Rosenteufel</h3>
        <p class="card-text">
          <span>
            &quot;Ich erzähl euch ein Gschicht von wahr, so wahr wie ihr danach
            nicht gwollt. Ein Gschicht dieerzähl als einzger überlebend: Mein
            Gschicht. S wart im letzten Jahr...&quot;
          </span>
          <br />
          <br />
          <span>
            Diese Kurzgeschichte, geschrieben in einem pseudo-mittelalterlichen
            Schreibstil, entstand aus einer Challenge die sich ein Freund und ich
            uns gegenseitig stellten, mit den Wörtern &quot;Sand&quot; und
            &quot;Rose&quot; je eine Kurzgeschichte zu schreiben.
          </span>
        </p>
        <router-link to="/kurzgeschichten/rosenteufel">
          <app-lesen class="summary-shortstories-component01"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Einsames Schaf</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            &quot;Fast wäre das Lamm von der Klippe gestürzt. Der Schäferhund
            hatte es nach dem Pfeifen des Hirten grade noch erreicht. Das Lamm
            versucht wieder und wieder sich von der Herde zu entfernen, nur um
            dann wieder eingefangen zu werden. Es scheint kein Interesse zu haben
            an den saftig grünen Wiesen. Während die anderen grasen, schaut es
            über die Klippen zum weiten Ozean.&quot;
          </span>
        </p>
        <router-link to="/kurzgeschichten/einsames-schaf">
          <app-lesen class="summary-shortstories-component02"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Gefangener Gorilla</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            &quot;Im Zoo sieht der kleine Sebastian einen jungen, kleinen Gorilla.
            Er sieht ganz traurig aus. Sebastian findet das schade. “Was hast du,
            kleiner Gorilla?” Als könne der Gorilla Sebastian verstehen, zeigt
            dieser auf den Zaun und macht dann einen Schmollmund.&quot;
          </span>
        </p>
        <router-link to="/kurzgeschichten/gefangener-gorilla">
          <app-lesen class="summary-shortstories-component03"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Lasst mich Ruhen!</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            &quot;So liege ich nun da in meiner Blutlache. Alles wird leicht…“Kein
            Puls,” meint die Polizistin. Das wäre es eigentlich gewesen. Doch mit
            einer neuen Methode, kann man über Hightech Geräte noch ein Mal kurz
            Leben einhauchen, um die Person zu befragen. Und so öffne ich wieder
            meine Augen, die in dem hellen Licht brennen.&quot;
          </span>
        </p>
        <router-link to="/kurzgeschichten/lasst-mich-ruhen">
          <app-lesen class="summary-shortstories-component04"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Die Lösung gegen Leid</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            “Unendliches Glück!”, posaunt ein Anzugträger neben mir. Ich krieche
            aus dem Schlafsack und streiche mir den angeflogenen Plastikabfall vom
            Körper. “Ah, endlich. Hören Sie, das ist Ihre Chance! [...]”
          </span>
        </p>
        <router-link to="/kurzgeschichten/die-loesung-gegen-leid">
          <app-lesen class="summary-shortstories-component05"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Infinite Deaths (English)</h3>
        <p class="card-text">
          <span>Shortstory on the topic of life value, October 2023</span>
          <br />
          <br />
          <span>
            “Every time you sleep, you could die during your sleep. Doesn’t that
            scare you?”
          </span>
          <br />
          <span>
            “But you do die every time you sleep. You are a different person when
            you wake up than when you went to sleep.”
          </span>
          <br />
          <span>“That doesn’t make sense. It’s still me.”</span>
          <br />
          <span>“Yes, but [...]”</span>
        </p>
        <router-link to="/kurzgeschichten/infinite-deaths">
          <app-lesen class="summary-shortstories-component06"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Versetztes Bedürfnis</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            “Du kannst also in die Vergangenheit reisen. Würdest Du jetzt also
            ihre Wertsachen stehlen? Es ist, wo Du lebst, ja eh schon alles
            passiert. Hier kannst Du einen Verlobungsring für ein Frühstück
            verkaufen. Oder solltest Du nicht direkt das Frühstück aus der
            Vergangenheit stehlen? Oder gar alles, was Du da findest?&quot;
          </span>
        </p>
        <router-link to="/kurzgeschichten/versetztes-beduerfnis">
          <app-lesen class="summary-shortstories-component07"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Was unterscheidet Himmel von Hölle?</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            “Du glaubst also, es ist schöner im Himmel als in der Hölle zu sein?
            Und nachdem du im Himmel alles erlebt hast, was es zu erleben gibt?
            Wenn all die Schönheiten zur Gewohnheit werden und du des Lebens müde
            wirst? Vielleicht nicht nach Jahrzehnten oder -hunderten, aber
            Jahrtausenden oder Millionen. [...]&quot;
          </span>
        </p>
        <router-link to="/kurzgeschichten/was-unterscheidet-himmel-von-hoelle">
          <app-lesen class="summary-shortstories-component08"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Leben statt Überleben</h3>
        <p class="card-text">
          <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
          <br />
          <br />
          <span>
            &quot;Nach der Katastrophe waren wir noch ein paar hundert Menschen im
            Bunker. Ursprünglich dachten wir, wir könnten irgendwann wieder raus
            an die Oberfläche, aber nein, wir mussten nach Monaten und Jahren
            feststellen, dass die Erde zu vergiftet ist. [...]&quot;
          </span>
        </p>
        <router-link to="/kurzgeschichten/leben-statt-ueberleben">
          <app-lesen class="summary-shortstories-component09"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Geträumtes Glück</h3>
        <p class="card-text">Kurzgeschichte zum Thema Liebe, Oktober 2023</p>
        <router-link to="/kurzgeschichten/getraeumtes-glueck">
          <app-lesen class="summary-shortstories-component10"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Klon oder Leid</h3>
        <p class="card-text">Kurzgeschichte zum Thema Liebe, Oktober 2023</p>
        <router-link to="/kurzgeschichten/klon-oder-leid">
          <app-lesen class="summary-shortstories-component11"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Ich will kein Hammer sein</h3>
        <p class="card-text">
          Kurzgeschichte zum Thema Freundschaft, Oktober 2023
        </p>
        <router-link to="/kurzgeschichten/ich-will-kein-hammer-sein">
          <app-lesen class="summary-shortstories-component12"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Teilung zweier Welten</h3>
        <p class="card-text">Kurzgeschichte zum Thema Konflikt, Oktober 2023</p>
        <router-link to="/kurzgeschichten/teilung-zweier-welten">
          <app-lesen class="summary-shortstories-component13"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Huhn rammt Huhn</h3>
        <p class="card-text">Kurzgeschichte zum Thema Konflikt, Oktober 2023</p>
        <router-link to="/kurzgeschichten/huhn-rammt-huhn">
          <app-lesen class="summary-shortstories-component14"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Heiliger Steinkrieg</h3>
        <p class="card-text">Kurzgeschichte zum Thema Konflikt, Oktober 2023</p>
        <router-link to="/kurzgeschichten/heiliger-steinkrieg">
          <app-lesen class="summary-shortstories-component15"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Unwichtigster Mensch</h3>
        <p class="card-text">Kurzgeschichte zum Thema Konflikt, Oktober 2023</p>
        <router-link to="/kurzgeschichten/unwichtigster-mensch">
          <app-lesen class="summary-shortstories-component16"></app-lesen>
        </router-link>
      </div>
      <div class="character-teaser-container">
        <h3 class="card-heading">Superkraft im Starbucks</h3>
        <p class="card-text">Kurzgeschichte zum Thema Konflikt, Oktober 2023</p>
        <router-link to="/kurzgeschichten/superkraft-im-starbucks">
          <app-lesen class="summary-shortstories-component17"></app-lesen>
        </router-link>
      </div>
    </div>
    <div class="summary-shortstories-row10 card-row">
      <div class="character-teaser-container">
        <h3 class="card-heading">Them or Us (English)</h3>
        <p class="card-text">
          Shortstory reinterpretating and combining ideas of two YouTube
          &quot;Kurzgesagt&quot; videos, November 2023
        </p>
        <router-link to="/kurzgeschichten/them-or-us">
          <app-lesen class="summary-shortstories-component18"></app-lesen>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import AppLesen from './lesen'

export default {
  name: 'SummaryShortstories',
  props: {
    rootClassName: String,
  },
  components: {
    AppLesen,
  },
}
</script>

<style scoped>
.summary-shortstories-component {
  text-decoration: none;
}
.summary-shortstories-component01 {
  text-decoration: none;
}
.summary-shortstories-component02 {
  text-decoration: none;
}
.summary-shortstories-component03 {
  text-decoration: none;
}
.summary-shortstories-component04 {
  text-decoration: none;
}
.summary-shortstories-component05 {
  text-decoration: none;
}
.summary-shortstories-component06 {
  text-decoration: none;
}
.summary-shortstories-component07 {
  text-decoration: none;
}
.summary-shortstories-component08 {
  text-decoration: none;
}
.summary-shortstories-component09 {
  text-decoration: none;
}
.summary-shortstories-component10 {
  text-decoration: none;
}
.summary-shortstories-component11 {
  text-decoration: none;
}
.summary-shortstories-component12 {
  text-decoration: none;
}
.summary-shortstories-component13 {
  text-decoration: none;
}
.summary-shortstories-component14 {
  text-decoration: none;
}
.summary-shortstories-component15 {
  text-decoration: none;
}
.summary-shortstories-component16 {
  text-decoration: none;
}
.summary-shortstories-component17 {
  text-decoration: none;
}
.summary-shortstories-row10 {
  margin-top: var(--dl-space-space-twounits);
}
.summary-shortstories-component18 {
  text-decoration: none;
}

@media(max-width: 991px) {
  .summary-shortstories-row10 {
    margin-top: var(--dl-space-space-halfunit);
  }
}
</style>
