<template>
  <div class="veranstaltungen-liste-veranstaltungen-liste">
    <div class="event-list">
      <div class="event-entry">
        <h3 class="event-title event-done">Run 1 (Schweiz)</h3>
        <div>
          <p class="event-section event-done">1. April 2023, 10:00 Uhr bis</p>
          <p class="event-section event-done">2. April 2023, 14:00 Uhr</p>
        </div>
        <p class="event-section event-done">{{ standortWeisslingen }}</p>
        <p class="event-section event-done-green">
          durchgeführt mit 15 Teilnehmenden
        </p>
      </div>
      <div class="event-entry">
        <h3 class="event-title event-done">Run 2 (Deutschland)</h3>
        <div>
          <p class="event-section event-done">
            13. Mai 2023, 10:00 Uhr bis
            <span v-html="rawgsbn"></span>
          </p>
          <p class="event-section event-done">14. Mai 2023, 14:00 Uhr</p>
        </div>
        <p class="event-section event-done">{{ standortHerten }}</p>
        <p class="event-section event-done-green">
          durchgeführt mit 15 Teilnehmenden
        </p>
      </div>
      <div class="event-entry">
        <h3 class="event-title event-done">Run 3 (Deutschland)</h3>
        <div>
          <p class="event-section event-done">
            1. Juli 2023, 10:00 Uhr bis
            <span v-html="rawhy39"></span>
          </p>
          <p class="event-section event-done">2. Juli 2023, 14:00 Uhr</p>
        </div>
        <p class="event-section event-done">{{ standortHerten }}</p>
        <p class="event-section event-done-green">
          durchgeführt mit 15 Teilnehmenden
        </p>
      </div>
      <div class="event-entry">
        <h3 class="event-title event-done">Run 4 (Deutschland)</h3>
        <div>
          <p class="event-section event-done">26. August 2023, 10:00 Uhr bis</p>
          <p class="event-section event-done">27. August 2023, 14:00 Uhr</p>
        </div>
        <p class="event-section event-done">{{ standortHerten }}</p>
        <p class="event-section event-done-green">
          durchgeführt mit 15 Teilnehmenden
        </p>
      </div>
      <div class="event-entry">
        <h3 class="event-title event-done">Run 5 (Schweiz)</h3>
        <div>
          <p class="event-section event-done">
            25. Mai 2024, 10:00 Uhr bis
            <span v-html="raw5w3e"></span>
          </p>
          <p class="event-section event-done">26. Mai 2024, 14:00 Uhr</p>
        </div>
        <p class="event-section event-done">{{ standortWeisslingen }}</p>
        <p class="event-section event-done-green">
          durchgeführt mit 15 Teilnehmenden
        </p>
      </div>
      <div class="event-entry">
        <h3 class="event-title event-done">Run 6 (Schweiz)</h3>
        <div>
          <p class="event-section event-done">01. Juni 2024, 10:00 Uhr bis</p>
          <p class="event-section event-done">02. Juni 2024, 14:00 Uhr</p>
        </div>
        <p class="event-section event-done">{{ standortWeisslingen }}</p>
        <p class="event-section event-done-green">
          durchgeführt mit 15 Teilnehmenden
        </p>
      </div>
    </div>
    <kommende-letzte-gelegenheit-runs
      rootClassName="kommende-letzte-gelegenheit-runs-root-class-name2"
    ></kommende-letzte-gelegenheit-runs>
  </div>
</template>

<script>
import KommendeLetzteGelegenheitRuns from './kommende-letzte-gelegenheit-runs'

export default {
  name: 'VeranstaltungenListe',
  props: {
    standortWeisslingen: {
      type: String,
      default: 'Zivilschutzanlage Sonnenblock Chilegass 8, 8484 Weisslingen',
    },
    standortHerten: {
      type: String,
      default: 'Waldritter e.V. (im Raumschiff) Ewaldstrasse 20, 45699 Herten',
    },
  },
  components: {
    KommendeLetzteGelegenheitRuns,
  },
  data() {
    return {
      rawgsbn: ' ',
      rawhy39: ' ',
      raw5w3e: ' ',
    }
  },
}
</script>

<style scoped>
.veranstaltungen-liste-veranstaltungen-liste {
  width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
}
</style>
