<template>
  <div class="superkraft-im-starbucks-container">
    <app-navigation rootClassName="navigation-root-class-name36"></app-navigation>
    <h1 class="page-title">Superkraft im Starbucks</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Konflikt, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/15U0qx7hxlhTMZmJCFBteCNUwm8FIOfDH/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="superkraft-im-starbucks-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Meine Superkraft ist etwas… Seltsam. Ich kann die Hintergründe für die
          Gefühle von Menschen spüren.
        </span>
        <br />
        <br />
        <span>
          Ich stehe bei Starbucks an der Schlange. Ein Kunde regt sich grade
          lauthals über die Barista auf: “Verdammt noch mal, ist es so schwierig
          Flavio zu schreiben? Mit einem ‘V’-au statt einem ‘W’-eh? Wohl in der
          Grundschule nicht aufgepasst. Kein Wunder musst du hier arbeiten!”
        </span>
        <br />
        <br />
        <span>
          Er ist wütend, weil er gerade ein Meeting zu seinem Projekt hatte, auf
          das er sich lange vorbereitet hat und sehr stolz ist, aber abgelehnt
          wurde, weil “gerade einfach die Kapazitäten der Firma fehlen”. Er fühlt
          sich nicht ernst genommen. Er hat sich ehrlich Mühe gegeben, weil er
          etwas Tolles schaffen will und andere, die sich nicht die Mühe wie er
          machen, haben es oft deutlich leichter. Das ist unfair. Und jetzt gibt
          sich nicht mal die Barista Mühe um ihn. Niemand gibt sich Mühe. Es sei
          zum Verzweifeln.
        </span>
        <br />
        <br />
        <span>
          Die Barista meint passiv aggressiv zurück: “Es tut mir Leid, dass Sie
          sich deswegen beleidigt fühlen.” Eigentlich macht sie den Job gerne und
          selbst nervige Kunden kann sie meistens ab, aber dieses dumme Vorurteil
          mit Bildung im Zusammenhang mit dem Beruf kann sie gar nicht ab. In der
          Gesellschaft gibt es doch nur Probleme, weil solche “Helden” denken,
          ihnen gehöre die Welt.
        </span>
        <br />
        <br />
        <span>
          Jemand stellt sich in die Lücke vor mir. “Ich muss dringend weiter”,
          meint sie. Was soll das? Geht’s noch? Ich sehe mein Spiegelbild in den
          flachen schwarzen Steinen ihrer Ohrringe. Ich fühle mich verletzt. Die
          Person hat nicht gefragt und so impliziert, dass ich offensichtlich
          weniger wichtig bin als sie. Ich könnte es ja auch eilig haben. Und wenn
          es wirklich so eilig wäre, könnte sie ja auf den Kaffee verzichten.
        </span>
        <br />
        <br />
        <span>
          Ich will mich grade echauffieren, als ich ihr in die Augen schaue. Sie
          fühlt sich gestresst und denkt im Tunnelblick. Sie denkt gar nicht an
          mich oder andere, sondern nur daran, dass sie gleich ein wichtiges
          Meeting hat. Statt zu explodieren, atme ich tief durch und meine:
          “Entschuldigen Sie. Ich finde das nicht in Ordnung. Sie sind bestimmt
          gestresst und ich gebe Ihnen gerne den Vortritt, aber wenn Sie mich
          nicht darum bitten, empfinde ich das als Provokation.” Wieso kann ich
          das erst, seitdem ich die Superkraft habe?
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name25"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'SuperkraftImStarbucks',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Superkraft im Starbucks',
    meta: [
      {
        name: 'description',
        content:
          '"Meine Superkraft ist etwas… Seltsam. Ich kann die Hintergründe für die Gefühle von Menschen spüren. Ich stehe bei Starbucks an der Schlange. Ein Kunde [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Superkraft im Starbucks',
      },
      {
        property: 'og:description',
        content:
          '"Meine Superkraft ist etwas… Seltsam. Ich kann die Hintergründe für die Gefühle von Menschen spüren. Ich stehe bei Starbucks an der Schlange. Ein Kunde [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.superkraft-im-starbucks-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.superkraft-im-starbucks-download-button {
  text-decoration: none;
}
</style>
