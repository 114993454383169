<template>
  <main class="card-rows-container" v-bind:class="rootClassName">
    <div class="card-row">
      <section class="character-teaser-container">
        <h1 class="card-heading">{{ wolfDesSuedens }}</h1>
        <p class="card-text">
          <span>
            &quot;Nachdem Langeweile sie dazu bringt, ihre Pflichten zu
            vernachlässigen, finden uralte, geächtete Kräfte ihren Weg zurück ins
            Licht. Solei, eine aussergewöhnliche Schülerin der arkanen Künste,
            flieht in eine Welt voller Angst und Verzweiflung. Auf einer Reise zu
            sich selbst lernt die junge Frau die Geister der Natur kennen und
            entdeckt ihre wahren magischen Kräfte.&quot;
          </span>
          <br />
          <br />
          <span>
            In diesem Trailer zu einem hypothetischen Kurzfilm experimentierten
            Freunde und ich mit low-budget Virtual Production.
          </span>
        </p>
        <router-link to="/kurzfilme/wolf-des-suedens">
          <div class="summary-shortfilms-button more-link">
            <p class="card-link">{{ mehrLink }}</p>
          </div>
        </router-link>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'SummaryShortfilms',
  props: {
    assemblatronBeschreibung: {
      type: String,
      default:
        'Alles was nur schiefgehen konnte, ging bei einem LARP schief. Ihr spielt die frustrierte Orga/SL (Organisation/Spielleitung) die sich über die “doofen” SC (Spielercharaktere) auslässt und die frustrierten SC, die sich über die “doofe” SL auslassen.',
    },
    wolfDesSuedensBeschreibung: {
      type: String,
      default:
        'Nachdem Langeweile sie dazu bringt, ihre Pflichten zu vernachlässigen, finden uralte, geächtete Kräfte ihren Weg zurück ins Licht. Solei, eine außergewöhnliche Schülerin der arkanen Künste, flieht in eine Welt voller Angst und Verzweiflung. Auf einer Reise zu sich selbst lernt die junge Frau die Geister der Natur kennen und entdeckt ihre wahren magischen Kräfte. In diesem Kurzfilm experimentieren wir mit neuen Technologien und gehen an die Grenzen dessen, was eine virtuelle Produktion mit einem geringen Budget leisten kann.',
    },
    assemblatron: {
      type: String,
      default: 'Assemblatron (Video Game)',
    },
    rootClassName: String,
    mehrLink: {
      type: String,
      default: 'Mehr ->',
    },
    wolfDesSuedens: {
      type: String,
      default: 'Die Legende vom Wolf des Südens (Trailer)',
    },
  },
}
</script>

<style scoped>
.summary-shortfilms-button {
  text-decoration: none;
}
.summary-shortfilms-button:hover {
  opacity: 0.5;
}


</style>
