<template>
  <div class="die-loesung-gegen-leid-container">
    <app-navigation rootClassName="navigation-root-class-name22"></app-navigation>
    <h1 class="page-title">Die Lösung gegen Leid</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/16XqhRQzP0uIwtT15Lt9q7qSodAbu4dIS/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="die-loesung-gegen-leid-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          “Unendliches Glück!”, posaunt ein Anzugträger neben mir. Ich krieche aus
          dem Schlafsack und streiche mir den angeflogenen Plastikabfall vom
          Körper. “Ah, endlich. Hören Sie, das ist Ihre Chance vom Dreck hier
          wegzukommen!”, sagt er. Den Gesichtsausdruck erkenne ich nicht, die
          Sonne blendet mich zu sehr. Ich will reden doch muss mich erstmal
          räuspern, bevor was rauskommt: “Steh mal ‘n Schritt nach links”
        </span>
        <br />
        <br />
        <span>“Bitte? Ah, natürlich!”</span>
        <br />
        <br />
        <span>
          Jetzt kann ich sein schattiges Gesicht sehen. Er grinst mich förmlich
          an. “Um was geht’s?”, hake ich genervt nach.
        </span>
        <br />
        <br />
        <span>
          “Um die Chance Ihres Lebens! Wir haben eine Maschine entwickelt, die all
          ihre Probleme löst. Wenn wir Sie anschliessen, erleben Sie für den Rest
          Ihres Lebens nur noch positive Hormone - Glücksgefühle!”
        </span>
        <br />
        <br />
        <span>“Aha… Und der Haken?”</span>
        <br />
        <br />
        <span>
          “Das ist das Beste: Keiner! Sie müssen nur mit mir mitkommen und wir
          erledigen das sofort”, sagt er während er mir eine Visitenkarte
          hinstreckt, “Ich arbeite für die Stadt im Kampf gegen die
          Obdachlosigkeit.”
        </span>
        <br />
        <br />
        <span>
          Ahja. Kampf gegen die Obdachlosigkeit. Schönere Formulierung als Kampf
          gegen die standortattraktivitätsmindernden Bettler.
        </span>
        <br />
        <br />
        <span>“Und das habe ich dann bis an den Rest meines Lebens?”</span>
        <br />
        <br />
        <span>
          “Ja, kaum zu glauben, was?” Er zeigt auf ein neues hochmodernes Gebäude
          ein paar Strassen weiter. “Da drüben. In dem Gebäude allein haben durch
          unsere effiziente Bauart über tausend Obdachlose Platz!”
        </span>
        <br />
        <br />
        <span>
          Klar, wenn man die Menschen stapeln kann wie in einem Archiv geht das.
        </span>
        <br />
        <br />
        <span>“Und da schläft man dann bis man stirbt?”</span>
        <br />
        <br />
        <span>
          “Nein, nein, sie sind wach. Aber Sie werden glücklich sein, glauben Sie
          mir. Sie können’s einfach mal versuchen.”
        </span>
        <br />
        <br />
        <span>
          Heroin soll schlimm sein aber das ist in Ordnung, weil es ja nicht
          lebensbedrohlich ist? Nein, weil es wohl Standortattraktivität erhöht.
        </span>
        <br />
        <br />
        <span>“Wir sorgen uns dann um Sie.”</span>
        <br />
        <br />
        <span>
          Um meine sterbliche Hülle bis sie stirbt meint er wohl. Was meint er
          eigentlich wer er ist? Dass ich mein Leben nicht als lebenswert sehe?
        </span>
        <br />
        <br />
        <span>
          “Warum lassen Sie sich nicht anschliessen wenn diese Maschine so toll
          ist?”
        </span>
        <br />
        <br />
        <span>
          Er schaut mich an, als sei ich die erste Person, die das einwendet.
        </span>
        <br />
        <br />
        <span>
          “Ist wohl mehr im Leben drin als möglichst viel glücklich zu sein, was?”
        </span>
      </p>
      <img
        alt="Eine Zeichnung, in der ein Mann mit Anzug und Fedora einem Obdachlosen mit langem Bart, der auf dem Boden sitzt, die offene Hand darbietend ausstreckt."
        src="/kurzgeschichten/openvisor_kurzgeschichte_zeichnung_die_loesung_gegen_leid-1200w.webp"
        class="shortstory-image"
      />
      <p class="die-loesung-gegen-leid-text56 shortstory-paragraph">
        Zeichnung von Lorena Zdanewitz.
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name15"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'DieLoesungGegenLeid',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Die Lösung gegen Leid',
    meta: [
      {
        name: 'description',
        content:
          '“Unendliches Glück!”, posaunt ein Anzugträger neben mir. Ich krieche aus dem Schlafsack und streiche mir den angeflogenen Plastikabfall vom Körper. “Ah, endlich. Hören Sie, das ist Ihre Chance! [...]”',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Die Lösung gegen Leid',
      },
      {
        property: 'og:description',
        content:
          '“Unendliches Glück!”, posaunt ein Anzugträger neben mir. Ich krieche aus dem Schlafsack und streiche mir den angeflogenen Plastikabfall vom Körper. “Ah, endlich. Hören Sie, das ist Ihre Chance! [...]”',
      },
    ],
  },
}
</script>

<style scoped>
.die-loesung-gegen-leid-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.die-loesung-gegen-leid-download-button {
  text-decoration: none;
}
.die-loesung-gegen-leid-text56 {
  font-style: italic;
  text-align: center;
}
@media(max-width: 991px) {
  .die-loesung-gegen-leid-text56 {
    font-style: italic;
  }
}
</style>
