<template>
  <div class="larps-container">
    <app-navigation rootClassName="navigation-root-class-name16"></app-navigation>
    <h1 class="page-title">LARPs</h1>
    <div class="text-body">
      <span>
        <span>
          LARP steht für Live Action Role Play, d.h. Live-Rollenspiel, d.h.
          Rollenspiel in dem man sich in eine fiktive Lebensform, meistens ein
          Mensch, einfühlt und sich so verkleidet und verhält wie man denkt dass
          der Charakter sich kleiden und verhalten würde.
        </span>
        <br />
        <br />
        <span>
          So wie es unterschiedliche Sportarten gibt gibt es unterschiedliche
          LARPs, z.B. Fantasy LARP oder Nordic LARP. Dabei können diese analog zu
          Sport so unterschiedlich sein wie z.B. Volleyball, Golf und Schach.
          OpenVisor hatte mit &quot;Süsse Träume&quot; ein kleines Fantasy LARP
          durchgeführt, konzentriert sich seither aber ausschliesslich auf Nordic
          LARPS.
        </span>
      </span>
    </div>
    <div class="card-container">
      <summary-larps
        rootClassName="summary-larps-root-class-name"
      ></summary-larps>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name9"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import SummaryLarps from '../components/summary-larps'
import AppKontakt from '../components/kontakt'

export default {
  name: 'LARPs',
  props: {},
  components: {
    AppNavigation,
    SummaryLarps,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - LARPs',
    meta: [
      {
        name: 'description',
        content:
          'LARP steht für Live Action Role Play, d.h. Live-Rollenspiel, d.h. Rollenspiel in dem man sich in eine fiktive Lebensform, meistens ein Mensch, einfühlt und sich so verkleidet und verhält.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - LARPs',
      },
      {
        property: 'og:description',
        content:
          'LARP steht für Live Action Role Play, d.h. Live-Rollenspiel, d.h. Rollenspiel in dem man sich in eine fiktive Lebensform, meistens ein Mensch, einfühlt und sich so verkleidet und verhält.',
      },
    ],
  },
}
</script>

<style scoped>
.larps-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
</style>
