<template>
  <div class="home-container">
    <app-navigation rootClassName="navigation-root-class-name11"></app-navigation>
    <img
      alt="OpenVisor"
      src="/openvisor_logo_only_text_compressed-200h.png"
      class="home-image"
    />
    <section class="home-kapitel-veranstaltungen">
      <header class="home-titel-veranstaltungen">
        <h2 class="uppercase section-head">Kommende Veranstaltungen</h2>
        <h2 class="home-section1 section-head">
          LARP &quot;Letzte Gelegenheit&quot;
        </h2>
      </header>
      <kommende-letzte-gelegenheit-runs
        rootClassName="kommende-letzte-gelegenheit-runs-root-class-name"
      ></kommende-letzte-gelegenheit-runs>
      <router-link to="/larps/letzte-gelegenheit" class="home-navlink">
        <div class="home-mehr more-link"><p class="card-link">Mehr -&gt;</p></div>
      </router-link>
    </section>
    <section class="card-container">
      <router-link to="/larps">
        <h2 class="home-titel-larps section-head">LARPs</h2>
      </router-link>
      <summary-larps></summary-larps>
    </section>
    <section class="card-container">
      <router-link to="/games">
        <h2 class="uppercase section-head">
          <span>Games</span>
          <br />
        </h2>
      </router-link>
      <summary-games></summary-games>
    </section>
    <section class="card-container">
      <router-link to="/kurzfilme">
        <h2 class="uppercase section-head">
          <span>Kurzfilme</span>
          <br />
        </h2>
      </router-link>
      <summary-shortfilms
        rootClassName="summary-shortfilms-root-class-name"
      ></summary-shortfilms>
    </section>
    <section class="card-container">
      <router-link to="/kurzgeschichten">
        <h2 class="uppercase section-head">
          <span>Kurzgeschichten</span>
          <br />
        </h2>
      </router-link>
      <summary-shortstories></summary-shortstories>
    </section>
    <section class="card-container">
      <router-link to="/blog">
        <h2 class="uppercase section-head">
          <span>Blog</span>
          <br />
        </h2>
      </router-link>
      <summary-blog rootClassName="summary-blog-root-class-name"></summary-blog>
    </section>
    <app-kontakt></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import KommendeLetzteGelegenheitRuns from '../components/kommende-letzte-gelegenheit-runs'
import SummaryLarps from '../components/summary-larps'
import SummaryGames from '../components/summary-games'
import SummaryShortfilms from '../components/summary-shortfilms'
import SummaryShortstories from '../components/summary-shortstories'
import SummaryBlog from '../components/summary-blog'
import AppKontakt from '../components/kontakt'

export default {
  name: 'Home',
  props: {},
  components: {
    AppNavigation,
    KommendeLetzteGelegenheitRuns,
    SummaryLarps,
    SummaryGames,
    SummaryShortfilms,
    SummaryShortstories,
    SummaryBlog,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor',
    meta: [
      {
        property: 'og:title',
        content: 'OpenVisor',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
}
.home-image {
  width: 640px;
}
.home-kapitel-veranstaltungen {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  border-color: #51515A;
  flex-direction: column;
  justify-content: center;
  border-top-width: 0px;
}
.home-titel-veranstaltungen {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.home-section1 {
  font-size: 28px;
}
.home-navlink {
  display: contents;
}
.home-mehr {
  position: relative;
  margin-top: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-titel-larps {
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-image {
    width: 420px;
  }
}
@media(max-width: 767px) {
  .home-image {
    margin-top: var(--dl-space-space-unit);
  }
  .home-kapitel-veranstaltungen {
    width: auto;
    border-color: transparent;
  }
}
@media(max-width: 479px) {
  .home-titel-veranstaltungen {
    width: auto;
    height: auto;
    max-width: auto;
    padding-top: 0px;
  }
  .home-section1 {
    font-size: 24px;
  }
}
</style>
