<template>
  <div class="gefangener-gorilla-container">
    <app-navigation rootClassName="navigation-root-class-name18"></app-navigation>
    <h1 class="page-title">Gefangener Gorilla</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1C_Llf_Msq5amhuRptQK691P0HMzIK1HP/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="gefangener-gorilla-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Im Zoo sieht der kleine Sebastian einen jungen, kleinen Gorilla. Er
          sieht ganz traurig aus. Sebastian findet das schade. “Was hast du,
          kleiner Gorilla?” Als könne der Gorilla Sebastian verstehen, zeigt
          dieser auf den Zaun und macht dann einen Schmollmund.
        </span>
        <br />
        <br />
        <span>
          Manch andere Tiere sind ja ganz glücklich hier. Sie kriegen zu fressen
          und werden gut behandelt, ohne sich Sorgen um Fressfeinde zu machen. Der
          Gorilla hier ist aber nicht glücklich. Er will die Welt sehen. Der
          Gorilla umschränkt sich mit seinen Armen und drückt fest zu. Er fühlt
          sich wohl eingesperrt. Sebastian entschliesst sich, dem Gorilla raus zu
          helfen. Als die Zoowärter die Gorillas füttern, legt Sebastian einen
          Kieselstein an den Türrahmen, bevor die Tür sich schliesst, sodass sie
          einen Spalt offen bleibt.
        </span>
        <br />
        <br />
        <span>
          Als der Zoo zu macht versteckt sich der kleine Sebastian hinter den
          Plüsch-Giraffen im Souvenirladen. Als es dunkel wird, geht Sebastian auf
          Fussspitzen ganz leise raus. Er schleicht zum Gorilla Gehege, wo der
          gleiche Gorilla noch immer an derselben Stelle ist, nur schläft er dort
          bereits. Sebastian klopft leise am Glas. Der Gorilla schaut ihn
          überrascht an. Er beginnt zu verstehen und grinst Sebastian an.
          Sebastian sieht, dass die Tür immer noch einen Spalt offen ist und macht
          dem Gorilla auf, der nicht zögert und auf seinen Fäusten zu Sebastian
          hintrottet. Sebastian macht wieder zu, vergisst vor Aufregung aber den
          Kieselstein weg zu machen.
        </span>
        <br />
        <br />
        <span>
          Sebastian geht mit dem Gorilla in den Wald, weil er Angst hat, dass
          sonst Leute ihn sehen und wieder einsperren wollen. Ein halber Mond
          zeigt sich und einige Sterne sind auch zu sehen. Sebastian zeigt ihm die
          schönen Stellen im Wald. Einen kleinen Wasserfall in den der Gorilla
          sofort reinhüpft und lachen muss. “Pssssscht!”, sagt ihm Sebastian, kann
          sich selbst aber ein fröhliches Kichern nicht verkneifen, als der
          Gorilla sein Gesicht im Wasserfällchen wäscht. Sie gehen weiter und der
          Gorilla kriegt kalt. Sebastian gibt ihm seine hellgrüne Jacke. Doch der
          Gorilla zeigt in seinen offenen Rachen und kreist die andere Hand um
          seinen Bauch. Er hat Hunger. Hm, hier im Wald hat es aber keine Bananen.
          Hier gibt es höchstens Pilze, aber Sebastian weiss nicht welche giftig
          sind und welche nicht. Und für einen ausgewachsenen Gorilla würde das
          sowieso nicht reichen. Erst jetzt fällt Sebastian ein, wie aussichtslos
          das Ganze ist. Er seufzt und erklärt dem Gorilla, dass es hier nichts zu
          essen gibt. Der Gorilla schmollt. Klar, könnte Sebastian dem Gorilla was
          zu essen bringen, aber was ist, wenn Sebastian krank ist? Sebastian will
          nicht, dass der Gorilla verhungert. Und da überschwemmt die Vernunft
          Sebastian auf traurige Art und Weise.
        </span>
        <br />
        <br />
        <span>
          “Wir müssen zurück, mein haariger Freund”, sagt Sebastian, “Ich kann
          nicht garantieren, dass du hier nicht verhungerst…” Dem Gorilla kullern
          ein paar Tränen runter. Sebastian umarmt ihn: “Ich werde dich aber
          besuchen kommen! Jede Woche wenn’s geht. Und dann spielen wir zusammen,
          durch die Glasscheibe!”
        </span>
        <br />
        <br />
        <span>
          Sie gehen zurück in den Zoo und nun ist Sebastian ganz froh, den
          Kieselstein vergessen zu haben. Noch bevor die Sonne aufging, war
          Sebastian auch wieder zuhause, wo seine besorgten Eltern ihn bereits
          erwarteten.
        </span>
        <br />
        <br />
        <span>
          Sebastian folgte seinem Versprechen und manchmal wiederholte er seine
          waghalsige Aktion, um den Gorilla zum Wasserfall und an andere schöne
          Orte im Wald zu bringen. Und selbst als der Gorilla doppelt so gross
          wurde wie Sebastian hat sich an ihrer Freundschaft nichts geändert. Es
          ist vielleicht nicht das, was der Gorilla sich wünschte, aber trotzdem
          freut sich der Gorilla nun auf jeden neuen Tag, denn es ist immer ein
          Tag näher an Sebastians regelmässigen Besuch.
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name11"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'GefangenerGorilla',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Gefangener Gorilla',
    meta: [
      {
        name: 'description',
        content:
          '"Im Zoo sieht der kleine Sebastian einen jungen, kleinen Gorilla. Er sieht ganz traurig aus. Sebastian findet das schade. [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Gefangener Gorilla',
      },
      {
        property: 'og:description',
        content:
          '"Im Zoo sieht der kleine Sebastian einen jungen, kleinen Gorilla. Er sieht ganz traurig aus. Sebastian findet das schade. [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.gefangener-gorilla-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.gefangener-gorilla-download-button {
  text-decoration: none;
}
</style>
