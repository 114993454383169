<template>
  <div class="infinite-deaths-container">
    <app-navigation rootClassName="navigation-root-class-name23"></app-navigation>
    <h1 class="page-title">Infinite Deaths</h1>
    <span class="page-subtitle">
      <span>Shortstory on the topic of life value, October 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1MFo_AWWeh2RYfkFgJ827FiSEJTlqKbu_/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="infinite-deaths-download-button button"
    >
      <span class="button-text">As PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          “Every time you sleep, you could die during your sleep. Doesn’t that
          scare you?”
        </span>
        <br />
        <br />
        <span>
          “But you do die every time you sleep. You are a different person when
          you wake up than when you went to sleep.”
        </span>
        <br />
        <br />
        <span>“That doesn’t make sense. It’s still me.”</span>
        <br />
        <br />
        <span>
          “Yes, but a different you. The you of yesterday didn’t have the dreams
          or the memories the you of today has. Also, even the slightest change in
          your body makes it a different body. Of course, the you of now couldn’t
          exist without the past you, but it is different. The longer the time
          between a past you and the now you and the bigger the difference you
          see. But no matter how long, there is always a difference.”
        </span>
        <br />
        <br />
        <span>
          “But then it isn’t just when I sleep. It’s any second that passes. Any
          fraction of time, I die. So living is just constantly dying.”
        </span>
        <br />
        <br />
        <span>
          “Exactly. There is no way you could go back and live with a past you,
          with the thoughts and composition of a past you. You can only live your
          now you, which means all the past yous are dead. So since you have kept
          dying since you were born, you shouldn’t fear when it is your last death
          of infinite deaths.”
        </span>
      </p>
      <img
        alt="A drawing in which a hooded skeleton holds an analog clock and is surrounded by analog clocks of different sizes."
        src="/kurzgeschichten/openvisor_kurzgeschichte_zeichnung_infinite_deaths-1200w.webp"
        class="shortstory-image"
      />
      <p class="infinite-deaths-text20 shortstory-paragraph">
        Drawing made by Lorena Zdanewitz.
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name16"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'InfiniteDeaths',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Infinite Deaths',
    meta: [
      {
        name: 'description',
        content:
          '“Every time you sleep, you could die during your sleep. Doesn’t that scare you?”\n“But you do die every time you sleep. You are a different person when you wake up than when you went to sleep.”\n...',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Infinite Deaths',
      },
      {
        property: 'og:description',
        content:
          '“Every time you sleep, you could die during your sleep. Doesn’t that scare you?”\n“But you do die every time you sleep. You are a different person when you wake up than when you went to sleep.”\n...',
      },
    ],
  },
}
</script>

<style scoped>
.infinite-deaths-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.infinite-deaths-download-button {
  text-decoration: none;
}
.infinite-deaths-text20 {
  font-style: italic;
  text-align: center;
}
@media(max-width: 991px) {
  .infinite-deaths-text20 {
    font-style: italic;
  }
}
</style>
