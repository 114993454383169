<template>
  <div class="ein-orden-fuer-eine-gute-tat-container">
    <app-navigation rootClassName="navigation-root-class-name"></app-navigation>
    <h1 class="page-title">
      <span>Ein Orden für eine gute Tat</span>
      <br />
    </h1>
    <span class="page-subtitle">
      <span>Eine moralische Kurzgeschichte für Kinder, Dezember 2020</span>
      <br />
    </span>
    <div class="ein-orden-fuer-eine-gute-tat-container1">
      <a
        href="https://drive.google.com/file/d/10oT5Mr9dZuApIKR_C7K765kU4pA9cg-m/view?usp=sharing"
        target="_blank"
        rel="noreferrer noopener"
        class="ein-orden-fuer-eine-gute-tat-download-button button"
      >
        <span class="button-text">Als PDF</span>
      </a>
    </div>
    <div class="ein-orden-fuer-eine-gute-tat-text-container text-body">
      <span>
        <span>
          &quot;Ein brauner Schneehase besucht ein ein Rothörnchen und ein
          Grauhörnchen im vom Herbst bunt gefärbten Wald. Er hat einen Orden, den
          er für eine gute Tat vergeben möchte. Doch welches Hörnchen schafft es,
          diesen zu bekommen?&quot;
        </span>
        <br />
        <br />
        <span>
          Diese Kurzgeschichte für Kinder und Eltern stellt spielerisch eine
          moralische Frage dar, dessen Antwort das Elternteil oder das Kind selbst
          finden muss. Es soll das Kind unterhalten und zugleich mit einer
          einfachen aber doch spannenden Fragestellung bilden.
        </span>
      </span>
      <img
        alt="image"
        src="/kurzgeschichten/openvisor_kurzgeschichte_zeichnung_einordenfuereinegutetat-1200w.png"
        class="shortstory-image"
      />
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name4"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'EinOrdenFrEineGuteTat',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Ein Orden für eine gute Tat',
    meta: [
      {
        name: 'description',
        content:
          'Ein brauner Schneehase besucht zwei Hörnchen im vom Herbst bunt gefärbten Wald. Er hat einen Orden, den er für eine gute Tat vergeben möchte.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Ein Orden für eine gute Tat',
      },
      {
        property: 'og:description',
        content:
          'brauner Schneehase besucht zwei Hörnchen im vom Herbst bunt gefärbten Wald. Er hat einen Orden, den er für eine gute Tat vergeben möchte. Doch welches Hörnchen schafft es, diesen zu bekommen?',
      },
    ],
  },
}
</script>

<style scoped>
.ein-orden-fuer-eine-gute-tat-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
}
.ein-orden-fuer-eine-gute-tat-container1 {
  display: flex;
  position: relative;
}
.ein-orden-fuer-eine-gute-tat-download-button {
  text-decoration: none;
}
.ein-orden-fuer-eine-gute-tat-text-container {
  width: 90%;
  max-width: 1080px;
  align-items: flex-end;
  align-content: center;
  justify-content: center;
}
@media(max-width: 991px) {
  .ein-orden-fuer-eine-gute-tat-text-container {
    width: 90%;
  }
}
</style>
