<template>
  <div class="lesen-button more-link" v-bind:class="rootClassName">
    <p class="card-link">{{ lesenLink }}</p>
  </div>
</template>

<script>
export default {
  name: 'Lesen',
  props: {
    lesenLink: {
      type: String,
      default: 'Lesen ->',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.lesen-button {
  position: relative;
}
</style>
