<template>
  <div class="ich-will-kein-hammer-sein-container">
    <app-navigation rootClassName="navigation-root-class-name30"></app-navigation>
    <h1 class="page-title">Ich will kein Hammer sein</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Freundschaft, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1eGFGTW0qhhl6um6z_QI3ppYF_bP3pXWT/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="ich-will-kein-hammer-sein-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>“Ich habe Angst davor, immer nur ein Hammer zu sein.”</span>
        <br />
        <br />
        <span>“Ein Hammer? Ich dachte, dir geht es um Freundschaft?”</span>
        <br />
        <br />
        <span>
          “Ja, genau. Ich habe aber das Gefühl, dass diese Freundschaft nur auf
          Nützlichkeit basiert. Wir teilen dieses Hobby und treffen uns dafür.
          Aber ohne das Hobby sehe ich auch keinen Sinn für weitere Treffen und
          ich denke die Person auch nicht.”
        </span>
        <br />
        <br />
        <span>“Hm, dann seid ihr wohl füreinander Hämmer.”</span>
        <br />
        <br />
        <span>
          “Aber wie findet man jemanden, wo es über gemeinsame Interessen
          hinausgeht? Was bindet andere Freunde zusammen, wenn sie nicht dieselben
          Interessen haben?”
        </span>
        <br />
        <br />
        <span>
          “Derselbe Vibe. Und ich schätze auch Loyalität. Finde jemanden, der sich
          wie du mit jemandem zufrieden gibt, der nicht nützlich für einen sein
          muss. Und ihr müsst einander irgendwie verstehen können. Empathie mit
          jedem ist gar nicht so einfach. Wenn man einander trotzdem bleibt, dann
          zeigt das viel mehr Freundschaft als der nützlichste Freund. Einfach
          gemeinsam in der Achterbahn des absurden Lebens zu sitzen ohne nach
          einem vermeintlich “besseren” Sitznachbarn zu suchen.”
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name6"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'IchWillKeinHammerSein',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Ich will kein Hammer sein',
    meta: [
      {
        name: 'description',
        content:
          '“Ich habe Angst davor, immer nur ein Hammer zu sein.”\n“Ein Hammer? Ich dachte, dir geht es um Freundschaft?”\n“Ja. Ich habe aber das Gefühl, dass diese Freundschaft nur auf Nützlichkeit basiert." [...]',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Ich will kein Hammer sein',
      },
      {
        property: 'og:description',
        content:
          '“Ich habe Angst davor, immer nur ein Hammer zu sein.”\n“Ein Hammer? Ich dachte, dir geht es um Freundschaft?”\n“Ja. Ich habe aber das Gefühl, dass diese Freundschaft nur auf Nützlichkeit basiert." [...]',
      },
    ],
  },
}
</script>

<style scoped>
.ich-will-kein-hammer-sein-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.ich-will-kein-hammer-sein-download-button {
  text-decoration: none;
}
</style>
