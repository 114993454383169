<template>
  <div class="wie-tales-inside-eine-einmalige-immersion-erreicht-container">
    <app-navigation rootClassName="navigation-root-class-name44"></app-navigation>
    <span class="page-title">
      Wie Tales Inside eine einmalige Immersion erreicht
    </span>
    <span class="page-subtitle">
      <span>
        Recherche, ursprünglich geposted auf mitoffenemvisier.com am 29ten Januar
        2021
      </span>
      <br />
    </span>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-vorgehen uppercase section-head"
    >
      Vorgehen
    </h1>
    <div class="text-body">
      <p>
        <span>
          Bei der Planung eigener LARPs möchte ich dieselbe Immersion erreichen,
          wie ich das am LARP Tales Inside erlebt habe. Um herauszufinden, was
          eben diese Immersion ausmacht, habe ich 10 Tales Inside Teilnehmenden
          Fragen zu ihren Erlebnissen gestellt. Die Früchte dieser Arbeit stelle
          ich hier vor.
        </span>
        <br />
        <br />
        <span>
          Drei der Interviewpartner sind erfahrene LARPer. Die restlichen 7 sind
          mit Tales Inside ins Hobby eingestiegen. Eines haben die 10 aber
          gemeinsam: Sie werden alle an weiteren Tales Inside Runs teilnehmen. Die
          Immersion spielt bei der Erfahrung eine tragende Rolle.
        </span>
        <br />
        <br />
        <span>
          Zuerst durften mir die Spieler jeweils mit 3 Adjektiven das Setting vom
          Tales Inside beschreiben. Ich erhielt verschiedene Antworten, doch
          einige Wörter kamen immer wieder vor:
        </span>
        <br />
        <span>- dystopisch</span>
        <br />
        <span>- emotional</span>
        <br />
        <span>- (extrem) strukturiert</span>
        <span><span v-html="raw3jei"></span></span>
        <span><span v-html="raw0zib"></span></span>
        <br />
      </p>
      <img
        alt="image"
        src="/tales-inside/zentralwerkstatt-zeche-lohberg-1200w.webp"
        class="shortstory-image"
      />
      <p class="blog-caption">
        <span>
          Diese Werkhalle wird für ein paar Tage zu einem Untergrundbunker mit 200
          Bewohnern
          <span v-html="raw64gk"></span>
        </span>
        <span><span v-html="raw0vw0"></span></span>
        <br />
      </p>
      <p>
        <span>
          Danach durften sie das Setting so kurz wie möglich zusammenfassen. Das
          Spannende dabei war, wie unterschiedlich das Setting vom Tales Inside
          beschrieben wurde. Die kürzeste Beschreibung war: «Deutsche Bürokratie
          trifft Nineteen Eighty-Four». Wenn ich die Aussagen wie ein Puzzle
          zusammensetze, ergibt sich aber eine umfassendere Beschreibung:
        </span>
        <br />
        <br />
        <span>
          In einer fiktiven Zukunft wurde die Erde durch eine nukleare Katastrophe
          unbewohnbar. Nach mehreren Jahrhunderten leben aber noch 200 Menschen in
          einem Bunker mit steter Ressourcenknappheit. Im Bunker herrscht eine KI
          und ein totalitäres Kastensystem bei dem Unterdrückung, Ungerechtigkeit
          und tragische Schicksale an der Tagesordnung stehen. Hierbei werden die
          negativsten als auch die positivsten Eigenschaften der Menschheit
          gezeigt und intensiv dargestellt/ausgespielt.
        </span>
        <br />
        <br />
        <span>
          Das Erlebnis ist sehr lehrreich über politische Extreme, verdeckte
          Machtkämpfe innerhalb eines Regimes, gegenseitige Bespitzelung,
          Zersetzung, Gruppenzwang/Mitläufertum, Hemmungsverlust von Gewalt und
          weitere soziale Phänomene. Man lernt ausserdem eigene Einstellungen und
          Werte auf einer Metaebene praktisch kennen.
        </span>
        <span><span v-html="raw12r2"></span></span>
        <span><span v-html="rawt1qg"></span></span>
        <br />
      </p>
    </div>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-vorgehen-titel uppercase section-head"
    >
      Das Setting vom Tales Inside
    </h1>
    <div class="text-body">
      <p>
        <span>Zusammenfassung aus Aussagen der 10 Spielenden:</span>
        <br />
        <br />
        <span>
          «In einer fiktiven Zukunft wurde die Erde durch eine nukleare
          Katastrophe unbewohnbar. Nach mehreren Jahrhunderten leben aber noch 200
          Menschen in einem Bunker mit steter Ressourcenknappheit. Im Bunker
          herrscht eine KI und ein totalitäres Kastensystem bei der Unterdrückung,
          Ungerechtigkeit und tragische Schicksale an der Tagesordnung stehen.
          Hierbei werden die negativsten als auch die positivsten Eigenschaften
          der Menschheit gezeigt und intensiv dargestellt/ausgespielt.
          <span v-html="rawgbus"></span>
        </span>
        <br />
        <br />
        <span>
          as Erlebnis ist sehr lehrreich über politische Extreme, verdeckte
          Machtkämpfe innerhalb eines Regimes, gegenseitige Bespitzelung,
          Zersetzung, Gruppenzwang/Mitläufertum, Hemmungsverlust von Gewalt und
          weitere soziale Phänomene. Man lernt ausserdem eigene Einstellungen und
          Werte auf einer Metaebene praktisch kennen.»
        </span>
        <br />
      </p>
      <img
        alt="image"
        src="/tales-inside/zentralwerkstatt-zeche-lohberg-fabrikhalle-1200w.jpg"
        class="shortstory-image"
      />
      <p class="blog-caption">
        <span>
          Beim Reaktoralarm wird diese Halle mit Nackenhaar-aufstellenden Sirenen
          und der Decke entlang schleichenden Blinklichtern geflutet (Bild von
          Ludger Bott).
        </span>
        <span><span v-html="rawh9pq"></span></span>
        <span><span v-html="rawaogc"></span></span>
        <br />
      </p>
    </div>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-immersion-titel uppercase section-head"
    >
      Die Immersion
    </h1>
    <div class="text-body">
      <p>
        <span>
          Bei den erfahrenen 3 LARPern und 2 Einsteigern, welche danach an andere
          LARPs gegangen sind, war die Immersion das, was Tales Inside von anderen
          LARPs abhebt. Bei allen war die Immersion aber einfach überwältigend
          gut.
        </span>
        <br />
      </p>
      <img
        alt="image"
        src="/tales-inside/2019-10_hagenhoppephotographer_event_talesinside4_0341-1200w.jpg"
        class="shortstory-image"
      />
      <p class="blog-caption">
        <span>
          Man tat nicht nur so, als sei man der Charakter, man fühlte und agierte,
          als sei man wirklich da; vollkommene Immersion.
        </span>
        <br />
      </p>
      <p>
        Bei der Frage, was die Leute denken, dass die Immersion ausmacht, waren
        viele Antworten überschneidend. Bei fast jeder Person kamen aber auch neue
        Facetten und Gedanken hinzu. Ich versuche, diese hier zusammenzufassen.
      </p>
    </div>
    <div
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-immersion-rows card-container"
    >
      <div class="character-row">
        <div class="character-teaser-container">
          <h3 class="card-heading">1. Die Kulisse</h3>
          <p class="card-text">
            <span>
              Die Fabrikhalle ist an sich schon eindrücklich und lässt einen
              Teilnehmenden bereits als klein und unwichtig fühlen. Aber erst die
              Lichteffekte und die Geräuschkulisse holen das volle Potenzial aus
              dem fiktiven Bunker. Denn durch die hohe Decke und weitläufigem Gang
              kommt der Reaktoralarm mit blinkenden Lampen und die hallenden
              Ansagen der künstlichen Intelligenz «IRIS» so richtig zur Geltung.
            </span>
            <br />
            <br />
            <span>
              Dazu kommen ein eigens für das LARP gebauter simulierter
              Atomreaktor, Aufzug zur Oberfläche über dem Bunker, eine
              Gefängniszelle, Propagandaplakate und speziell für die jeweilige
              Kaste eingerichtete Räume.
            </span>
            <br />
            <br />
            <span>
              Gleiche Uniformen sorgen dafür, dass Individualismus schon beim
              Aussehen ausgemerzt wird, was beim unterdrückenden Setting vom Tales
              Inside eine zentrale Rolle spielt. Sogar das Essen, das von manchen
              liebevoll «Bunkerbrei» genannt wird, passt perfekt zum Setting – ist
              im Kontrast zum Aussehen aber sehr lecker.
            </span>
          </p>
        </div>
        <div class="character-teaser-container">
          <h3 class="card-heading">2. Individuelle Intensitätstufen</h3>
          <p class="card-text">
            <span>
              Am Tales Inside gibt es 3 Intensitätsstufen, die man vor dem Spiel
              auswählen kann, aber während dem Spiel noch ändern kann. Wer das
              volle Erlebnis auf dem Tales Inside auskosten möchte, wählt Stufe 3,
              wobei man effektiv z.B. Essensmarken braucht, um Essen zu erhalten
              oder mitten in der Nacht in einer Razzia oder vom Reaktoralarm
              geweckt wird.
            </span>
            <br />
            <br />
            <span>
              Der Vorteil an den Intensitätsstufen ist, dass jeder Spieler die
              Intensität auswählen kann, welche genau richtig für sich selbst ist,
              um die maximale Immersion zu erreichen und trotzdem nicht die
              eigenen Grenzen überschreiten zu müssen. Von mehreren
              Interviewpartnern wurde für Einsteigende empfohlen, die höchste
              Intensitätsstufe zu wählen: Es ist das volle Erlebnis und wenn es
              einem zu viel wird, kann man jederzeit die Stufe senken.
            </span>
          </p>
        </div>
      </div>
      <div class="character-row">
        <div class="character-teaser-container">
          <h3 class="card-heading">3. Verlust des Zeitgefühls</h3>
          <p class="card-text">
            <span>
              Es dauert nicht lange und man hat in der Halle sämtliches Zeitgefühl
              verloren. Uhren gab es nicht. Uhrzeiten wurden nur an den
              KI-Terminals angezeigt. Ein Spieler hatte in einem Moment einen
              Lichtschein aus einem kleinen Schlitz der verklebten Fenster
              festgestellt und war überrascht, denn gefühlsmässig hätte er
              gedacht, es sei bereits Nacht.
            </span>
            <br />
            <br />
            <span>
              Ja, die meisten Befragten hatten am Ende des LARPs das Gefühl viel
              mehr als nur drei bzw. vier Tage in dem Bunker verbracht zu haben.
            </span>
            <br />
            <br />
            <span>
              Lustig war auch, dass die KI Terminals stellenweise andere Uhrzeiten
              angezeigt hatten – passend zum Setting, wobei der Bunker wegen der
              langen Nutzung immer wieder Mängel aufweist. Wegen der
              unterschiedlichen Zeiten an den Terminals und dem unterschiedlichen
              Zeitgefühl wusste niemand mehr, welche Zeit wir wirklich hatten.
            </span>
          </p>
        </div>
        <div class="character-teaser-container">
          <h3 class="card-heading">4. Das Platzparadox</h3>
          <p class="card-text">
            <span>
              Versteht mich nicht falsch, die Werkhalle war sehr gross. Doch für
              200 Leute, die dauernd hin und her gehen, ist das immer noch relativ
              eng. Das führte zu intensiverem und dadurch immersiverem Spiel ohne
              Leerlauf. Wie man im Physikunterricht gelernt hat: Je mehr Atome auf
              einer Stelle sind, desto mehr Reaktionen gibt’s.
            </span>
            <br />
            <br />
            <span>
              Und das Ironische am Ganzen: Die Fläche im Bunker ist weitaus
              kleiner als die der grossen Fantasy-LARPs Felder und trotzdem fühlte
              die Werkhalle sich voller und weiträumiger an. Wohl auch, weil alles
              in der Halle Spielbereich war und auch als solcher genutzt wurde.
              Behauptung von mir: Selbst beim Essen in getrennten Kasten spielte
              man weiterhin so aktiv miteinander, wie man es von Bankett-LARPs nur
              erträumen könnte. Wir denken, es geht hier ähnlich wie mit der Zeit:
              Wenn mehr passiert fühlt sich die Zeit länger an und wenn mehr in
              einem Raum passiert, fühlt sich der Raum weitläufiger an.
            </span>
          </p>
        </div>
      </div>
      <div class="character-row">
        <div class="character-teaser-container">
          <h3 class="card-heading">5. Konfliktförderndes Setting</h3>
          <p class="card-text">
            <span>
              Ähnlich wie der Raum ist auch das Gesellschaftssystem sehr
              engmaschig angelegt. Es bietet klare Eckpunkte, in denen man sich
              frei bewegen kann. Aus dem Ganzen hat sich während dem Spiel eine
              gewisse Gesellschaftskultur und auf der Metaebene eine gewisse
              Spielkultur entwickelt.
            </span>
            <br />
            <br />
            <span>
              Individualismus wird im Keim erstickt. Im Gegensatz zu anderen LARPs
              bei denen Gewandungen kreuz und quer herreichen können, wird hier
              für das Setting bewusst auf Variation verzichtet. Charaktere
              bekommen eine Bürgernummer statt Namen. Letzteres zu haben ist gar
              verboten, wie alles andere, das jemandem zum Individuum machen
              könnte.
            </span>
            <br />
            <br />
            <span>
              Umso intensiver sind Charakterinteraktionen, in denen das
              hervorgehoben wird, was die Kleidung nicht zeigt. Ich denke, das ist
              genauso hilfreich wie das Trinken von Wasser vor der Degustation
              eines Weins. Wie ein Charakter handelt, steht im Vordergrund und
              nicht wie er aussieht.
            </span>
            <br />
            <br />
            <span>
              Die Vorgesetzten hat man «Sir» zu nennen (egal welches Geschlecht)
              und Gleichgestellte oder Untergebene «Bürger» (auch hier generischer
              Maskulinum). Mir konnten alle Spielenden bestätigen, dass Floskeln
              wie diese sich am Ende des LARPs so sehr in das Gehirn eingebrannt
              haben, dass man dem Flugbegleiter oder der Busschaffnerin kurz
              darauf fast aus Versehen «Sir» sagt und Erinnerungen aus dem Bunker
              getriggert werden, sobald man «Sir» oder «Bürger» hört.
            </span>
            <br />
            <br />
            <span>
              Dazu kommt ein ausgetüfteltes Gesellschafts-, Bürokratie- und
              Hierarchiesystem, das bewusst darauf ausgelegt ist zu Konflikt zu
              führen. Dabei wird auch nie Anspruch erhoben, ein realistisches
              System erschaffen zu haben – doch selbst da:
            </span>
            <br />
            <br />
            <span>
              Bei der Frage, ob das System für seinen Zweck, das Überleben der
              Menschheit, sinnvoll ist, gehen die Meinungen stark auseinander,
              auch mit verschiedensten Argumentationen. Ich denke, das zeigt, dass
              das «System» existierende soziale Phänomene und theoretische
              gesellschaftliche Ideen auf eine glaubhafte Art und Weise verbindet.
            </span>
          </p>
        </div>
        <div class="character-teaser-container">
          <h3 class="card-heading">6. Gut geschriebene Charaktere</h3>
          <p class="card-text">
            <span>
              Sämtliche Charaktere sind aufeinander und auf das Setting
              eingestellt. Alleine mit seiner Rolle im System, quasi seinem Beruf,
              hat man meistens was zu tun. Das passt auch zum Setting, à la: «Wenn
              sie keine Zeit zum Denken haben können sie auch nicht hinterfragen.»
            </span>
            <br />
            <br />
            <span>
              Dann kommen Charakterbeziehungen, charakterspezifische Plots und der
              Hauptplot dazu. Man ist teilweise so von Spielmöglichkeiten
              überhäuft, dass man sich für eine Storyline entscheiden muss. Und
              selbst wenn man den Hauptplot komplett ignoriert, kann man ein
              grandioses Erlebnis haben.
            </span>
            <br />
            <br />
            <span>
              Die Charaktere sind gut geschrieben und hier zeigt sich, wie sehr es
              sich lohnt darin Zeit zu investieren. 200 Leute ist dabei eine gute
              Anzahl für die Spielenden, denn in dem engmaschigen Setting kennt
              man irgendwann fast jedes Gesicht. Im Leben und in LARPs ist man
              immer der Protagonist der eigenen Geschichte, so natürlich auch im
              Tales Inside, doch hier bekommt man Geschichten anderer in Echtzeit
              hautnah mit, ohne unbedingt viel mit ihnen zu tun zu haben.
            </span>
            <br />
            <br />
            <span>
              Ich denke ein grosses Kompliment ist diese Aussage eines
              Interviewpartners: «Ich dachte oft wie mein Charakter und nicht wie
              ich selbst, so sehr war ich in diese Welt vertieft.»
            </span>
          </p>
        </div>
      </div>
      <div class="character-row">
        <div class="character-teaser-container">
          <h3 class="card-heading">7. Spielfördernde Workshops</h3>
          <p class="card-text">
            <span>
              Kurz vor dem Spiel gibt es noch einige Workshops. Dabei werden
              nochmals Beziehungen zwischen Charaktere geknüpft, z.B. Freunde und
              Rivalen. Es gibt auch kastenspezifische Workshops. Die Security
              Kaste wird z.B. mit dem Umgang des Schaumstoff Tonfas und auf den
              Arrest von Charakteren geschult. Das ist vor allem für
              LARP-Einsteigende sehr wertvoll und senkt die Hemmung später im
              Spiel enorm, was wiederum die Immersion fördert.
            </span>
            <br />
            <br />
            <span>
              Nach den Workshops wechselt das Event über einen Song innerhalb
              weniger Minuten ins Rollenspiel über. Im Nachhinein betrachtet ist
              es überraschend, wie gut das so schnell funktioniert hat. Ein
              Kommentar meinerseits: Als das Rollenspiel dann mit einem Song
              wiederum beendet wurde, fühlte es sich für mich an, als wache man
              aus einem Traum auf, den man bis zu dem Moment als Realität
              akzeptiert hat. Das ist mir bei keinem anderen LARP bisher so
              passiert.
            </span>
            <br />
            <br />
            <span>
              Nach dem Spiel gibt es auch noch einen Workshop, um das Ganze
              miteinander zu verarbeiten. Man kann die Erlebnisse in Gedanken
              nochmals miteinander durchleben, was auch seinen Wert hat. Man hat
              am Event «noch etwas zu knabbern gehabt».
            </span>
          </p>
        </div>
        <div class="character-teaser-container">
          <h3 class="card-heading">8. Unterstützende Mitspielende</h3>
          <p class="card-text">
            <span>
              Das Tales Inside zieht aus irgendeinem Grund eine tolle Art Menschen
              an. Menschen, die ausserhalb des Spiels rücksichtsvoll zueinander
              sind und während dem Spiel gegenseitiges Spiel fördern – unbewusst
              wie bewusst.
            </span>
            <br />
            <br />
            <span>
              Das LARP lebt von einer aktiven Community, die bei der Organisation
              und dem Aufbau des LARPs mithilft, einander unterstützt und/oder auf
              neue Runs hyped. Wenn man sich angemeldet hat, kann man bereits
              miteinander Beziehungen knüpfen, dafür gibt es dedizierte
              Facebook-Gruppen. Das alles trägt am Spiel auch zur Immersion bei.
            </span>
            <br />
            <br />
            <span>
              Abgesehen davon: Das für manche Überraschendste ist, zu was für
              wertvollen neue Freundschaften und gar Liebesbeziehungen das LARP
              geführt hat. Bei manchen hat sich ihr Leben dadurch grundsätzlich im
              Positivem verändert. Vielleicht liegt das eben auch daran, dass das
              LARP Menschen mit ähnlichen Interessen zueinander führt und das
              intensive Erlebnis diese gleich näher zueinander führt.
            </span>
            <br />
            <br />
            <span>
              Nach der Aussage eines Spielers hat man nie das Gefühl alleine zu
              sein, kann sich aber jederzeit zurückziehen und alleine sein, wenn
              man das möchte. Das mag banal sein, doch wer das Experiment mit dem
              Panik-Knopf kennt: Alleine schon eine Ausstiegsmöglichkeit zu haben
              beruhigt.
            </span>
          </p>
        </div>
      </div>
      <div class="character-row">
        <div class="character-teaser-container">
          <h3 class="card-heading">9. Konstante Bedrohung</h3>
          <p class="card-text">
            <span>
              Hier noch ein persönlicher Kommentar: Das Überleben des Bunkers ist
              jederzeit bedroht, z.B. durch Schäden am in die Jahrhunderte
              gekommener Reaktor. Und selbst abgesehen davon ist das eigene Leben
              und die wenigen Freiheiten, die man noch hat, jederzeit bedroht,
              durch Konsequenzen eigener Aktionen doch auch über Aktionen anderer.
              Selbst wenn man nur seinen Job macht und systemkonform ist, kann man
              trotzdem jederzeit Ungerechtigkeit erfahren.
            </span>
            <br />
            <br />
            <span>
              Der Bedrohung hilft auch, dass das LARP auch in Runs stattfindet,
              bei denen ein Run so schiefgehen darf, im Sinne, dass zum Beispiel
              der Reaktor explodiert. Andere Runs werden davon nämlich nicht
              beeinflusst.
            </span>
          </p>
        </div>
      </div>
    </div>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-titel uppercase section-head"
    >
      Eine Kaste für jeden Geschmack
    </h1>
    <div class="text-body">
      <p>
        <span>
          Wie bereits festgestellt sind die Kasten des Systems so
          zusammengestellt, dass sie automatisch Spiel untereinander erzeugen und
          die Immersion fördern.
        </span>
        <span><span v-html="raw45pq"></span></span>
        <br />
      </p>
      <img
        alt="image"
        src="/tales-inside/2019-10_hagenhoppephotographer_event_talesinside4_0447-1024x-1200w.jpg"
        class="shortstory-image"
      />
      <p class="blog-caption">
        <span>
          Meritokratische Dystopie oder das notwendige Übel zur Sicherstellung der
          Spezies?
        </span>
        <br />
      </p>
      <p>
        <span>
          In derselben Kaste kann man völlig anderes Spiel haben kann – gar mit
          dem gleichen Charakter wie gerade festgestellt. Und in verschiedenen
          Kasten natürlich auch anderes Spiel, sind die Kasten so gut, dass
          verschiedene Spielstile. Für verschiedene Spielstile und Rollenspiel
          Erfahrung gibt es auch verschiedene Kasten. Ich versuche ihre
          Eigenschaften hier kurz aufzulisten:
        </span>
        <span><span v-html="rawzh7c"></span></span>
        <br />
      </p>
    </div>
    <div
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-rows card-container"
    >
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-row1 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/kasten-arbeiter-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">Arbeiter</h2>
              <h3 class="character-function">(Orange)</h3>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Die hierarchisch tiefste Kaste, bei der man entsprechend unterdrückt
            wird. Hier gibt es dafür auch die unterschiedlichsten Aufgaben und
            entsprechend auch grosse Abwechslung. Hier hat man Spielangebot im
            Überfluss und erlebt was Tales Inside alles kann. Eigenschaften dieser
            Kaste sind Solidarität und Ungerechtigkeit (von anderen). In dieser
            Kaste wird es emotional, dem kann man gar nicht ausweichen.
            Entsprechend zeigt diese Kaste gerade für LARP Einsteiger das volle
            Potenzial von LARP.
          </p>
        </section>
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/kasten-versorger-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">
                Versorger
                <span v-html="rawzhjv"></span>
              </h2>
              <h3 class="character-function">(Weiss)</h3>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Arztpersonal, Pflegende und Ressourcenverteilung; Wenn man diese Kaste
            die Kaste der Humanitären nennen würde, liegt man falsch. Ihre Aufgabe
            ist immer abzuschätzen, ob das Leben eines Bürgers für die
            Gesellschaft von Vorteil ist oder ob es zu viele Ressourcen frisst.
            Der Eid des Hippokrates ist hier ein Fremdwort im wahrsten Sinne des
            Wortes. Diese Kaste ist vor allem für Spieler, die sogenanntes
            Heilerspiel (physisch und psychisch) oder Verteilerfunktionen mögen
            geeignet. Ich kann sie LARP Einsteigern nicht empfehlen, weil man
            anfangs nicht weiss welches Spiel man mag.
          </p>
        </section>
      </div>
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-row11 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/kasten-security-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">
                <span>Security</span>
                <br />
              </h2>
              <h3 class="character-function">
                <span>(Blau)</span>
                <br />
              </h3>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Jedes System braucht eine Exekutive, um stabil zu bleiben. Mit Tonfa,
            Handschellen und Trillerpfeife setzt diese Kaste die Unterdrückung mit
            Präsenzdarbietung und abschreckenden Ad-hoc-Strafen praktisch um.
            Eigenschaften der Kaste sind Mitläufertum und Kameradschaft. Diese
            Kaste ist auch für LARP Einsteiger geeignet, da die Ordnung
            strukturiert und die Aufgaben klargestellt sind.
          </p>
        </section>
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/kasten-gesellschafter-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">
                <span>Gesellschafter</span>
                <br />
              </h2>
              <h3 class="character-function">
                <span>(Grün)</span>
                <br />
              </h3>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Diese Kaste trägt viel zur Gehirnwäsche und dem Dystopie-Feeling des
            Bunkers bei. Sie ist quasi die Exekutive des aufgezwungenen
            Glücklich-Seins. Denn nur ein glücklicher Bürger ist ein produktiver
            Bürger. Auf der Tales Inside Webseite wird diese Kaste als geeignet
            für LARP Einsteiger beschrieben.
          </p>
        </section>
      </div>
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-row3 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/kasten-administration-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">Administration</h2>
              <h3 class="character-function">(Rot)</h3>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Die hierarchisch höchste Kaste, bei der man entsprechend Autorität
            ausstrahlt. Die anderen sehen dabei Rot im wahrsten Sinne des Wortes.
            Das Symbol der Unterdrückung im System. Eigenschaften der Kaste sind
            Entscheidungsgewalt und interner Machtkampf. Wer nicht aufpasst, kann
            sich schnell in einem tieferen Rang oder gar anderen Kaste
            wiederfinden. Die dunkelsten Geheimnisse des Systems stehen hier an
            der Tagesordnung. Diese Kaste sollten erfahrene Rollenspieler spielen,
            da sie doch auch Verantwortung für das Spiel der anderen mit sich
            bringt.
          </p>
        </section>
      </div>
    </div>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-titel uppercase section-head"
    >
      Charaktere der Interviewpartner
    </h1>
    <div class="text-body">
      <p>
        <span>
          Die Aussagen der Interviewpartner zeigt, wie immersiv und speziell das
          Tales Inside ist. Ich erläutere folgende Beispiele von
          Charakterentwicklungen, die in ihrer Kürze nicht mal annähernd dem
          Schwärmen der Spieler gerecht wird – kaum hatte ich eine Frage gestellt,
          entflossen die Erlebnisse nur so ihren Stimmbändern.
          <span v-html="raww5tt"></span>
        </span>
        <br />
      </p>
      <img
        alt="image"
        src="/tales-inside/zelle-1200w.jpg"
        class="shortstory-image"
      />
      <p class="blog-caption">
        So gleich sie alle aussehen, so individuell sind die Charaktere
        geschrieben – trotz Uniform und Bürgernummer.
      </p>
      <p>
        <span>
          Tim spielte zwei sehr ähnliche Charaktere und André gar zweimal
          denselben Charakter in der Security-Kaste und beide hatten trotzdem
          jedes Mal verschiedene Erlebnisse und Charakterentwicklungen.
        </span>
        <br />
      </p>
    </div>
    <div
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-rows card-container"
    >
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row1 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-tim-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">Tim</h2>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Tim hat als Schichtplan Security Gruppenzwang aus nächster Nähe
            miterlebt. Ihm wurde das so richtig bewusst, als fast die Gesamtheit
            der wehrlosen Gesellschafter niederknüppeln mussten. Später, als sein
            erster Charakter verletzt war, merkte er, wie fürsorglich die Arbeiter
            sich umeinander kümmern, während er ohne Mitgefühl auf kaltem Boden
            liegengelassen wird. Das hat diesen Charakter so sehr bewegt, dass er
            aufgehört hat extra hart zu den Arbeitern zu sein und er wurde gar
            nett zu ihnen.
          </p>
        </section>
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-anton-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">
                <span>Anton</span>
                <br />
              </h2>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Anton spielte einen Arbeitercharakter, der mit einer
            Manischen-Depression zu kämpfen hatte. Er übte immer wieder Streiche
            aus, war aber nicht grundsätzlich gegen das System, doch als nach
            einem Putsch die Administration geändert und damit härter gegen
            Arbeiter vorgegangen wurde, war er dagegen, was aus dem System wurde.
            Er war wütend über diese Ungerechtigkeit. Vieles hat ihn sehr
            mitgenommen: Bürger sind gestorben, die Security hat zur Abschreckung
            einen Reaktorwartungstrupp verprügelt, weil diese zu lange gebraucht
            hätten den Reaktor zu warten usw. Der Charakter war glücklich darüber,
            als am Ende des LARPs die alte Administration wiederhergestellt wurde
            und Besserung für die Arbeiter in Sicht war.
          </p>
        </section>
      </div>
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row2 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-rikki-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">Rikki</h2>
            </div>
            <div></div>
          </div>
          <span class="character-teaser-text">
            <span>
              Rikki hat zuerst einen sogenannten Schatten bei der Arbeiterkaste
              gespielt, d.h. einen «Bürger» der noch in einer Kaste ausgebildet
              wird, aber noch nicht Mitglied ist. Sie fand das angenehm, da sie
              erstmal LARP an sich kennenlernen wollte und hiermit eher eine
              Zuschauerrolle einnehmen durfte, wobei sie quasi sich selbst in
              diesem Setting spielen konnte.
            </span>
            <br />
            <br />
            <span>
              Rikkis zweiter Charakter war ursprünglich eine Security, der zu den
              Arbeitern strafversetzt wurde. Anfangs war ihr einziges Ziel zurück
              in die Security Kaste zu kommen. Sie hat ihre Arbeiterkameraden
              deswegen wie Dreck behandelt. Doch als sie von den Arbeitern immer
              wieder Rückhalt erhalten hat, hat sie das immer mehr zu schätzen
              gelernt und war letztendlich damit zufrieden, bei den Arbeitern zu
              sein.
            </span>
          </span>
        </section>
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-henning-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">
                <span>Henning</span>
                <br />
              </h2>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Hennings Charakter war einer derer, die die verschiedenen Marken wie
            Essensmarken ausgegeben hatte. Da die Marken gerade sehr knapp waren,
            veranlasste er, dass die Arbeiter doch «freiwillig» ihre Marken zu
            Gunsten der Gemeinschaft abgeben könnten. Da fühlte er sich ganz stark
            als «kleiner Mann». Er wurde aus Reorganisationsgründen recht schnell
            befördert und wurde dann plötzlich ganz wichtig. Fortan beauftragte er
            seine Untergebenen damit, die Markenausgabe zu tätigen, während er mit
            einem Administrator «rumspaziert» ist. Über Umwege kam er in Kontakt
            mit Drogen, von deren Abhängigkeit er schnell depressiv wurde. Da
            hatten sich seine Versorger-Kameraden dann so gut um ihn gekümmert,
            dass er sie fortan auch wieder unterstützte und dem System nicht nur
            fürs Machtgefühl, sondern auch für dessen Erfolg gedient hat.
          </p>
        </section>
      </div>
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row3 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-patrik-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">Patrik</h2>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Patrik spielte einen Versorger, der Probleme mit dem System hatte. Er
            wollte Freundschaften aufbauen, doch in dem System schien das für ihn
            praktisch unmöglich. Er verübte einen Mordanschlag auf den höchsten
            Administrator, weil dieser für ihn alles Böse im System symbolisierte.
            Er fand gegen Ende dann aber doch Freunde und stellte fest, dass das
            System so ist wie es sein muss. Gerade vor Schluss bedrohte ihn eine
            Security mit einem Gewehr und zwei Arbeiter sprangen vor ihn hin – ein
            toller Cliffhanger.
          </p>
        </section>
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-olli-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">
                <span>Oliver</span>
                <br />
              </h2>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            Oliver spielte einen Arbeiter, der schon sehr früh am System zu
            zweifeln begann. Er hat immer wieder Unruhe gestiftet und so seinen
            Administrator Freund in Bedrängnis gebracht. Den Arbeitern und der
            Security machte er dadurch den Alltag schwerer, als er hätte sein
            müssen. Doch als er sich zur Rettung seines Freundes mit einer
            Security und einer Versorgerin zusammentun musste, sah er das System
            aus neuen Blickwinkeln und lernte Verständnis mit Mitgliedern der
            anderen Kasten – vor allem mit der Security, die er anfangs nicht
            ausstehen konnte.
          </p>
        </section>
      </div>
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row4 character-row"
      >
        <section class="character-teaser-container">
          <div class="character-header">
            <img
              alt="Symbolbild"
              src="/tales-inside/charaktere-robin-200h.webp"
              class="character-image"
            />
            <div class="character-description">
              <h2 class="character-name">Robin</h2>
            </div>
            <div></div>
          </div>
          <p class="character-teaser-text">
            <span>
              Robin nahm an drei Runs teil. Sein erster Charakter wurde in letzter
              Sekunde gemacht und er war die meiste Zeit überflüssig. Da sieht man
              wie wichtig es ist, dass alle Charaktere aufeinander und die Welt
              abgestimmt sind. An zwei weiteren Runs hatte er aber dann den Spass
              seines Lebens, wobei er einen Admin und einen rangtiefsten Arbeiter
              spielte. So konnte er also die Hierarchie des Systems direkt aus
              zwei Extremen erleben.
            </span>
            <br />
            <br />
            <span>
              Robin hat als systemtreuer Administrator einen Gegenputsch in der
              Admin-Kaste organisiert und hat sich über mehrere Erlebnisse in
              seiner Systemtreue und dem System bestätigt gefühlt.
            </span>
            <br />
            <br />
            <span>
              Robins Arbeiter-Charakter war dagegen völlig anders. Er war
              systemtreu, doch fand, dass die Arbeiter gegen der Idee des Systems
              ungerecht behandelt wurden. Ihn hat das so frustriert, dass er
              aufmüpfig und abhängig von Hustensaft wurde und ihn das zu immer
              weiteren Problemen geführt hat. Sämtliche Nächte hat er in der
              Gefängniszelle verbracht. Letztendlich hat er das grösste Opfer
              gebracht, das er bringen konnte, um seinen Arbeiterkollegen immerhin
              diesen Schmerz abzunehmen.
            </span>
          </p>
        </section>
      </div>
    </div>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-fazit-titel uppercase section-head"
    >
      Fazit
    </h1>
    <div class="text-body">
      <p>
        <span>
          Um Immersion zu erzeugen wie sie am Tales Inside erreicht wird müssen
          viele Faktoren miteinander funktionieren. Es zeigt sich, dass die Macher
          vom Tales Inside bereits viel Erfahrung in entsprechenden Bereichen
          haben. Wer diese Immersion einmal erlebt hat kennt das grosse Potenzial
          von LARP Events. Auf jeden Fall bietet mir Tales Inside eine grosse
          Inspirationsquelle für LARPs die ich künftig organisieren werde.
        </span>
        <br />
      </p>
    </div>
    <h1
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-nachwort-titel uppercase section-head"
    >
      Nachwort
    </h1>
    <div class="text-body">
      <p>
        <span><span v-html="raw79xj"></span></span>
        <span>
          Ich erhebe mit diesem Artikel keinen Anspruch darauf die Wahrheit
          herausgefunden zu haben. Es ist lediglich mein Versuch vergangene
          Erlebnisse mithilfe anderer Spieler auf Immersion zu destillieren.
        </span>
        <br />
        <br />
        <span>
          Vom Deutschlandfunk gibt es hier einen Beitrag der weitere Facetten des
          Tales Inside beleuchtet:
        </span>
        <br />
        <a
          href="https://www.hoerspielundfeature.de/live-rollenspiele-gespielte-welten-100.html"
          target="_blank"
          rel="noreferrer noopener"
          class="wie-tales-inside-eine-einmalige-immersion-erreicht-link"
        >
          https://www.hoerspielundfeature.de/live-rollenspiele-gespielte-welten-100.html
        </a>
        <br />
        <br />
        <span>Für einen Teaser Trailer zum Tales Inside hier klicken:</span>
        <br />
        <a
          href="https://www.youtube.com/watch?v=gXrCH_1TiqM"
          target="_blank"
          rel="noreferrer noopener"
          class="wie-tales-inside-eine-einmalige-immersion-erreicht-link1"
        >
          https://www.youtube.com/watch?v=gXrCH_1TiqM
        </a>
        <br />
        <br />
        <span>
          Vielen Dank an Tim, André, Robin, Henning, Rikki, Patrik, Oliver und
          Anton die auf ein Interview eingewilligt und geduldig auf diesen Artikel
          gewartet haben. Diese tollen Menschen habe ich über das Tales Inside
          kennengelernt. Danke auch an
          <span v-html="raw844v"></span>
        </span>
        <a
          href="https://www.facebook.com/fotofaenger/"
          target="_blank"
          rel="noreferrer noopener"
          class="wie-tales-inside-eine-einmalige-immersion-erreicht-link2"
        >
          Fotofänger
        </a>
        <span>
          ,
          <span v-html="rawk18f"></span>
        </span>
        <a
          href="https://hagenhoppe.com/"
          target="_blank"
          rel="noreferrer noopener"
          class="wie-tales-inside-eine-einmalige-immersion-erreicht-link3"
        >
          Hagen Hoppe
        </a>
        <span>
          und
          <span v-html="rawybzr"></span>
        </span>
        <a
          href="https://www.facebook.com/people/Del-Ink-Fotografie/100044552704394"
          target="_blank"
          rel="noreferrer noopener"
          class="wie-tales-inside-eine-einmalige-immersion-erreicht-link4"
        >
          Del-Ink
        </a>
        <span>für die tollen Bilder.</span>
        <br />
        <br />
        <span>
          Ein grosses Lob an die Erschaffer vom Tales Inside: Armin Saß und
          Jorina Havet. Sie haben mit dem Tales Inside eine Messlatte fürs LARP
          gesetzt wie wir sie uns zuvor nur erträumen konnten.
        </span>
        <br />
        <br />
        <span>
          Falls ich etwas oder jemanden vergessen habe, teilt mir das bitte mit.
          🙂
        </span>
        <br />
        <br />
        <span class="wie-tales-inside-eine-einmalige-immersion-erreicht-text181">
          Update 2023: Die Tales Inside Orga hat mit dem Projekt abgeschlossen und
          offiziell auf ihrer Webseite bestätigt, dass sie keine weiteren Runs
          durchführen werden. Sie werden sich stattdessen neuen LARPs widmen.
        </span>
        <br />
      </p>
    </div>
    <a
      href="https://otherlifegames.de/inside/"
      target="_blank"
      rel="noreferrer noopener"
      class="wie-tales-inside-eine-einmalige-immersion-erreicht-link5"
    >
      <div
        class="wie-tales-inside-eine-einmalige-immersion-erreicht-tales-inside-webseite button"
      >
        <span class="button-text">
          <span>offizielle Tales Inside Webseite</span>
          <br />
        </span>
      </div>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name32"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'WieTalesInsideEineEinmaligeImmersionErreicht',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  data() {
    return {
      raw3jei: ' ',
      raw0zib: ' ',
      raw64gk: ' ',
      raw0vw0: ' ',
      raw12r2: ' ',
      rawt1qg: ' ',
      rawgbus: ' ',
      rawh9pq: ' ',
      rawaogc: ' ',
      raw45pq: ' ',
      rawzh7c: ' ',
      rawzhjv: ' ',
      raww5tt: ' ',
      raw79xj: ' ',
      raw844v: ' ',
      rawk18f: ' ',
      rawybzr: ' ',
    }
  },
  metaInfo: {
    title: 'OpenVisor - Wie Tales Inside eine einmalige Immersion erreicht',
    meta: [
      {
        name: 'description',
        content:
          'Tales Inside ist ein Vorbild was Immersion im LARP angeht. Um herauszufinden, was eben diese ausmacht, habe ich 10 Teilnehmende interviewt. Hier die Resultate.',
      },
      {
        property: 'og:title',
        content:
          'OpenVisor - Wie Tales Inside eine einmalige Immersion erreicht',
      },
      {
        property: 'og:description',
        content:
          'Tales Inside ist ein Vorbild was Immersion im LARP angeht. Um herauszufinden, was eben diese ausmacht, habe ich 10 Teilnehmende interviewt. Hier die Resultate.',
      },
    ],
  },
}
</script>

<style scoped>
.wie-tales-inside-eine-einmalige-immersion-erreicht-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-vorgehen {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-vorgehen-titel {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-immersion-titel {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-immersion-rows {
  padding-top: var(--dl-space-space-twounits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-titel {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-rows {
  padding-top: var(--dl-space-space-twounits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-row1 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-row11 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-kasten-row3 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-titel {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-rows {
  padding-top: var(--dl-space-space-twounits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row1 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row2 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row3 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-charaktere-row4 {
  position: relative;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-fazit-titel {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-nachwort-titel {
  padding-top: var(--dl-space-space-sixunits);
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-link {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-link1 {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-link2 {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-text168 {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-link3 {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-text170 {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-link4 {
  text-decoration: underline;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-text181 {
  font-style: italic;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-link5 {
  display: contents;
}
.wie-tales-inside-eine-einmalige-immersion-erreicht-tales-inside-webseite {
  text-decoration: none;
}
</style>
