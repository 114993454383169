<template>
  <div class="lasst-mich-ruhen-container">
    <app-navigation rootClassName="navigation-root-class-name21"></app-navigation>
    <h1 class="page-title">Lasst mich Ruhen!</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1YiIKPWQJeTv_xi10z7ayyZQEPa4boUwG/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="lasst-mich-ruhen-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>So liege ich nun da in meiner Blutlache. Alles wird leicht…</span>
        <br />
        <br />
        <span>
          “Kein Puls,” meint die Polizistin. Das wäre es eigentlich gewesen. Doch
          mit einer neuen Methode, kann man über Hightech Geräte, noch ein Mal
          kurz Leben einhauchen, um die Person zu befragen. Und so öffne ich
          wieder meine Augen, die in dem hellen Licht brennen. “Wie sah die
          Tatperson aus?”, fragen sie mich. Es sei eine Ausnahme, man könne den
          Fall nicht anders lösen. Ich gebe ihnen schnell eine Beschreibung, um
          endlich wieder Frieden zu finden.
        </span>
        <br />
        <br />
        <span>
          Doch wieder wache ich auf. In einem… Labor? Archäologie Fachpersonen in
          weissen Kitteln fragen mich, wie manches “damals” war. Es sei eine
          Ausnahme, man brauche nur Gewissheit über das ein oder andere. Genervt
          gebe ich die gewünschten Antworten, um wieder die ewige Ruhe zu finden.
        </span>
        <br />
        <br />
        <span>
          Und wieder werde ich geweckt. Die Leute sehen wieder anders aus, tragen
          dieses Mal seltsame Uniformen. Sie fragen mich, wie die Grenzen mancher
          Länder zu meiner Zeit aussahen – wohl ging es um einen Kriegsgrund oder
          so. Es sei eine Ausnahme, man brauche die Aussage ja nur ein Mal.
        </span>
        <br />
        <br />
        <span>
          Ach, wenn doch nur… Wenn wir doch nur hätten bestimmen können, dass wer
          seinen Frieden kriegt, diesen auch behalten darf. Ohne Ausnahmen. Wenn
          es auch nur eine einzige Ausnahme geben kann, wird es auf unendliche
          Zeit unendlich viele Ausnahmen geben. Ich will doch nur meinen Frieden…
        </span>
      </p>
      <img
        alt="Eine Zeichnung, in der eine Hand sich nach einem grellen Licht streckt, aber Bänder, die um das Handgelenk gewickelt sind, halten die Hand zurück."
        src="/kurzgeschichten/openvisor_kurzgeschichte_zeichnung_lasst_mich_ruhen-1200w.webp"
        class="shortstory-image"
      />
      <p class="lasst-mich-ruhen-text17 shortstory-paragraph">
        Zeichnung von Lorena Zdanewitz.
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name14"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'LasstMichRuhen',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Lasst mich Ruhen!',
    meta: [
      {
        name: 'description',
        content:
          '"So liege ich nun da in meiner Blutlache. Alles wird leicht…“Kein Puls,” meint die Polizistin. Das wäre es eigentlich gewesen. Doch mit einer neuen Methode, kann man über Hightech Geräte noch [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Lasst mich Ruhen!',
      },
      {
        property: 'og:description',
        content:
          '"So liege ich nun da in meiner Blutlache. Alles wird leicht…“Kein Puls,” meint die Polizistin. Das wäre es eigentlich gewesen. Doch mit einer neuen Methode, kann man über Hightech Geräte noch [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.lasst-mich-ruhen-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.lasst-mich-ruhen-download-button {
  text-decoration: none;
}
.lasst-mich-ruhen-text17 {
  font-style: italic;
  text-align: center;
}
@media(max-width: 991px) {
  .lasst-mich-ruhen-text17 {
    font-style: italic;
  }
}
</style>
