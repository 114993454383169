<template>
  <div class="rosenteufel-container">
    <app-navigation rootClassName="navigation-root-class-name"></app-navigation>
    <h1 class="page-title">Rosenteufel</h1>
    <span class="page-subtitle">
      <span>
        Experimentelle Kurzgeschichte in pseudo-mittelalterlichen Schreibstil,
        Februar 2023
      </span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1sfDZA6DcMrKUx0VfKngPJPk4BC84Umcr/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="rosenteufel-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <span class="shortstory-paragraph">
        <span class="rosenteufel-text004">
          Ich erzähl euch ein Gschicht von wahr. So wahr wie ihr danach nicht
          gwollt. Ein Gschicht die erzähl als einzger überlebend: Mein Gschicht. S
          wart im letzten Jahr...
        </span>
        <br />
        <br />
        <span>
          Vogt Eberhardt: “Edler von Petz, habt Dank um Eure schnelle Peitsch zu
          Ross. S eilt, der Bot kaum angekomm’n, doch bereits geschehn in letzter
          Woch.”
        </span>
        <br />
        <br />
        <span>
          Ritter von Petz: “Gott’s Segen. Wie Euch zu dien’n, durchlaucht?”
        </span>
        <br />
        <br />
        <span>
          Vogt Eberhardt: “Im Nord herrsch Brand und Stank. Räuberband
          wahrscheinch, nicht zu schätz woher. Leichen sein gesetzt ins Ufer,
          jeder Leich ein Blum obendrein. So gefund ein Kind. Rest ausgegraben,
          all vom selben Weiler. S’ist im Weiler Immerthain...”
        </span>
        <br />
        <br />
        <span>Tage der Reise vergangen, d</span>
        <span>er Ritter im Wege sich erkundigt...</span>
        <br />
        <br />
        <span>Köhler: “In Immerthain?”</span>
        <br />
        <br />
        <span>
          Ritter von Petz: “Stottert ich? Willst du des meinen Blutes Wege mit
          Steinen pflastern oder daraufe legen. Fahret fort, sonst kostet’s dich
          mehr als mein Geduld.”
        </span>
        <br />
        <br />
        <span>
          Köhler: “Entschuld mein Entsetz Edler, nur wisst, der Teufel treibt dort
          sein Feuer. Die nicht ermordert flohen mit aller Hab. Nur wenige
          blieben. Möge sich der Herr ihnen erbarmen...”
        </span>
        <br />
        <br />
        <span>
          Ritter von Petz greift ihm mit seinem rechten Panzerhandschuh am Kragen:
          “Lass den Frevel, des schnellsten Wegs sollst du mich weisen!”
        </span>
        <br />
        <br />
        <span>
          Köhler: “Verzeiht’s mir Edler, verzeiht’s. Der schnellste Weg führt
          durch den Kammertwald. Hie wo ich zeig überm Pfad. Zwei Tag, ein Nacht.
          Aber... Edler, bei Gotts Gnad, ich wärt kein gut Christ Euch ohne Warn
          lass schreiten. Seit Geschehnem ist hie kein einzger entlang. Wer weiss
          was
          <span v-html="rawcp2k"></span>
        </span>
        <span>Geziefer dort treibt...”</span>
        <br />
        <br />
        <span>
          Ritter von Petz: “Tieft den Zinken nun. Hast getan was gesagt. Wartet
          mein Antlitz in einem Dutzend Tag – nicht spät. Sollt ich kehr, so ritz
          ich dies Holz mit eir Botschaft. Andernfalls berichtet meins Schicksal.”
        </span>
        <br />
        <br />
        <span>Köhler verneigt sich: “Gott mit Euch.”</span>
        <br />
        <br />
        <span>
          Ritter von Petz dreht sich: “Auf jetzt, Gefolg. Noch die letzten
          Schritte nicht geschritten.”
        </span>
        <br />
        <br />
        <span>Doch s dauert nicht lang...</span>
        <br />
        <br />
        <span>
          Knappe von Jachstett: “Herr, der Pfad’s dunkel, doch kaum erst zu Mittag
          gebrochen. Noch fall zu Tod bevor eins Feinds Kling mich blickt.”
        </span>
        <br />
        <br />
        <span>
          Knecht Troffecht: “Ja edler Herr, und Wurzeln überall. S Maultier und
          Eur Ross sich oft vertritt, könnt knacks s Glenk.”
        </span>
        <br />
        <br />
        <span>
          Magd Ute: “Will nicht fächern den Ärger, aber wenn Gotts ehrl’ch Tugend
          g’wahr, Wagens Achs könnt brech in Mitt.”
        </span>
        <br />
        <br />
        <span>Knappe von Jachstett: “Edler Herr...”</span>
        <br />
        <br />
        <span>
          Ritter dreht zu Rück, zeigt mit grüstet Finger, all’s hält ein: “So
          zügle er seine Zunge, der sich gewagt meins Gfährten zu schwörn! So halt
          er den Mund der nur striegelt und Dung sticht, um seines Lebens kein
          Sorg! Und so bleibe sie still solang sie noch eines Brots erbetteln
          möcht. Ihr all seid feig und Kloss an mei’m Bein.”
        </span>
        <br />
        <br />
        <span>Der Ritter sich schlicht’, die traurig Gsicht beäug.</span>
        <br />
        <br />
        <span>
          “So kehr er um der sich des Wegs nicht mutig, aber versichre, dass kein
          Gnad zeig wem wieder erblick! Wer weiss wieviel noch gemordt währn ihr
          quängelt wie neugeborns, nackts Kindlein. Erinnert Eberhardts Wort: Acht
          tot in acht Nacht. Sieht mein Stundglas. Seit hee s rieselt. Ich gedreht
          jed Stund. Nach zehn weiter Dreh unds letzt Korn gerieselt s des
          nächsten arm Seel dessen euch verantwort. So zügelt und schluckt der
          schwächend Quängelei.”
        </span>
        <br />
        <br />
        <span>
          S wart still für lange Stund, bis zum Abendfeuer. S Nacht, einzig Licht
          von Flamm durch einig Stämm bricht, man schaut kein zwanzg Schritt weit.
        </span>
        <br />
        <br />
        <span>
          Knappe von Jachstett leis zum Knecht Troffecht: “Seltsam Gräusch,
          erhorch.”
        </span>
        <br />
        <br />
        <span>
          Knecht Troffecht bleicher Schaur übern Rück: “D-d-d-der Teufel is’s?”
        </span>
        <br />
        <br />
        <span>Magd Ute, d Supp rührnd, flüstert zu: “Was war?”</span>
        <br />
        <br />
        <span>
          Knappe von Jachstett die Achsel zuck, sich erhebt und zu Ritter von Petz
          schreit’, der dabei sein Kling zu schärf.
        </span>
        <br />
        <br />
        <span>
          Knappe von Jachstett: “Edler Herr, gehört Geräusch vom Walde. S der
          Teufel wir när schreit. Uns verdammt hie auf Glut mit barem Fuss. Bei
          Gott, Euch verwerf nicht den Mut zu gehn in wackren Kampf. Aber schauet
          nur ins Gsicht von einfach Leut, Knecht und Magd. Beid mit Angst, des
          Herrn kein Mittel. So lässt hie in sicher, nicht drohn ihrets Blut.
          Schon meiner mir erbarme, aber deren Leben verworfen sobald in selbe
          Flammen waten.”
        </span>
        <br />
        <br />
        <span>
          Ritter von Petz aber nicht der Zunge, nur sein Kling weiterschärf.
        </span>
        <br />
        <br />
        <span>
          Morg früh der Knapp verlorn. Kein Spur wart gsehn, nur sein Hab schwund
          mit ihm. Ritter lässt sein Harnisch von Knecht Troffecht anbring.
        </span>
        <br />
        <br />
        <span>
          Knecht Troffecht: “Edler Herr, wisst wo sich Herrn von Jachstett
          treibt?”
        </span>
        <br />
        <br />
        <span>
          Ritter von Petz geradeaus schreitend: “Der sich seins Entscheids
          bereut.”
        </span>
        <br />
        <br />
        <span>
          Unds wart kein weiter Wort gesprochen, bis zum Weiler angekomm. Am Ufer,
          man sah, s wart ein in pechschwarz Mantel gehüllt Gestalt, Kapuz über
          Gesicht hängend. S leert eins Hands Sand über einen Sandhaufen der
          bereits eins Manns lang wie breit. Ritter zittert erst, reisst die Zügel
          in Knechts Hand, schleicht so gut sein Harnisch’s vermag. Sein Eisen in
          Höh, in tiefer Sonn s blinkt.
        </span>
        <br />
        <br />
        <span>Ritter von Petz: “Schmeck Gotts Gericht, Elender!”</span>
        <br />
        <br />
        <span>
          Ein Stich und Gestalt fällt. Magd Ute eilt her. Ritter dreht die Gestalt
          mit panzerm Fuss. Magd schrickt, Händ vorm Mund. Ritter von Petz schaut
          zur Magd, wartet und schaut dann zum Knecht.
        </span>
        <br />
        <br />
        <span>
          Ritter spricht zur Magd, kommt zum Knecht mit seufz: “Aufgab’s
          vollbracht. Des Teufels bedarfs kein Grab, ihn lässt nur rotten solangs
          ihm möglich, sein Höll auf Erd. Wir nun kehren und vielleicht des Knapp
          einholn.”
        </span>
        <br />
        <br />
        <span>
          Magd Ute taugte keiner Worte, Knecht Troffecht verwirrt. Doch zu
          widersetzen wärt der letzt Gedank und so sie kehrten wider für restlich
          Tagesstund.
        </span>
        <br />
        <br />
        <span>
          In Nacht keiner ein Aug zugetan. Des Ritters Kling noch rot, er sie
          nicht sauber gefragt und der Knecht viel zu blass als ihm vorzukommen.
          Letztlich doch die Augen sich schlossen. Doch wachten nur noch Ritter
          und Knecht. Des Ritters Petz Kling noch immer rot; des Knechts Haut
          <span v-html="rawh0jc"></span>
        </span>
        <span>
          noch immer weiss und nass. Dieses Mal der Knecht nicht wagte zu fragen
          der Verschwundenen.
        </span>
        <br />
        <br />
        <span>
          S musst sein bereits früh Mittag doch der Wald noch alls dunkelt. So
          gepackt die Sachen der Ritter seines Schrittes eilig. Doch der Knecht
          stramm stehend sich nicht bewegt.
        </span>
        <br />
        <br />
        <span>Ritter von Petz: “Los jetzt, wir müssen schnell zurück!”</span>
        <br />
        <br />
        <span>
          Schnell? Wieso? Die Tat wart doch vollbracht und noch viele Tag ins Holz
          zu ritzen.
        </span>
        <br />
        <br />
        <span>
          Der Knecht blickt zurück. Ritter greift zur Klinge. Knecht rennt. Er
          rennt und rennt, so schnell er kann. Ritter hinterher, ohne Harnisch
          flink, flinker als der Knecht. Doch der Knecht nicht stumpf und so
          bricht in den dunklen Wald. Seine Fährte der Ritter rasch verlorn.
        </span>
        <br />
        <br />
        <span>
          Ritter von Petz: “Komm her! Wer soll sonst meine Habe tragen! So mache
          rasch und dir tue nichts! Troffecht! Troffecht daher!”
        </span>
        <br />
        <br />
        <span>
          Doch sein Stimm bald verblasst. Ich kam zum Weiler, klein Leut bereits
          gestanden um die düstre Gstalt. Daneben ein weiterer, gleicher Haufen
          mit einer weissen Ros in Mitt. Zehn Häufen, all geschmückt mit weisser
          Ros. All bis auf der vord Gstalt. Kindsvolk starrte mir.
        </span>
        <br />
        <br />
        <span>
          Ausser Atem ich auf meine Knie mich stütz. Frau und Mann vom Weiler trat
          zuher. Sie starrten; auch kalt und hilflos wie Kind. Ich schritt nach
          vorn: In die Händ des Toten seh, weiss Ros. Ins Gesicht des Toten blick.
          Beulen zierten’s. Hässliche Beulen. Der Teufel aber wart woanders...
          <span v-html="rawcq47"></span>
        </span>
      </span>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name1"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'Rosenteufel',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  data() {
    return {
      rawcp2k: ' ',
      rawh0jc: ' ',
      rawcq47: ' ',
    }
  },
  metaInfo: {
    title: 'OpenVisor - Rosenteufel',
    meta: [
      {
        name: 'description',
        content:
          '"Ich erzähl euch ein Gschicht so wahr, wie ihr danach nicht gwollt. Ein Gschicht die erzähl als einzger überlebend: Mein Gschicht. S wart im letzten Jahr..."',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Rosenteufel',
      },
      {
        property: 'og:description',
        content:
          '"Ich erzähl euch ein Gschicht von wahr. So wahr, wie ihr danach nicht gwollt. Ein Gschicht die erzähl als einzger überlebend: Mein Gschicht. S wart im letzten Jahr..."',
      },
    ],
  },
}
</script>

<style scoped>
.rosenteufel-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.rosenteufel-download-button {
  text-decoration: none;
}
.rosenteufel-text004 {
  font-style: italic;
}
</style>
