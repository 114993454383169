<template>
  <div class="kurzgeschichten-container">
    <app-navigation rootClassName="navigation-root-class-name15"></app-navigation>
    <h1 class="page-title">Kurzgeschichten</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte</span>
      <br />
    </span>
    <div class="text-body">
      <span>
        <span>
          Hermann nutzt Kurzgeschichten um Ideen, die er spannend findet, kompakt
          erzählerisch zusammenzufassen. Künftig möchte Hermann ähnliche Ideen
          zusätzlich als Kurzfilme umsetzen, wenn das Filmformat dafür geeignet ist.
        </span>
        <br />
        <br />
        <span>
          Weitere Kurzgeschichten von Fabio Geisser, einem Freund von Hermann, sind zu finden auf dessen Webseite:
        </span>
        <a
            href="https://geschichtenfabio.ch"
            target="_blank"
            rel="noreferrer noopener"
            class="card-link"
            style="text-align:center;
            text-decoration:underline;"
          >
            https://geschichtenfabio.ch
        </a>
      </span>
    </div>
    <div class="kurzgeschichten-container1 card-container">
      <summary-shortstories
        rootClassName="summary-shortstories-root-class-name"
      ></summary-shortstories>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name8"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import SummaryShortstories from '../components/summary-shortstories'
import AppKontakt from '../components/kontakt'

export default {
  name: 'Kurzgeschichten',
  props: {},
  components: {
    AppNavigation,
    SummaryShortstories,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Kurzgeschichten',
    meta: [
      {
        name: 'description',
        content:
          'Hermann nutzt Kurzgeschichten um Ideen, die er spannend findet, kompakt erzählerisch zusammenzufassen.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Kurzgeschichten',
      },
      {
        property: 'og:description',
        content:
          'Hermann nutzt Kurzgeschichten um Ideen, die er spannend findet, kompakt erzählerisch zusammenzufassen.',
      },
    ],
  },
}
</script>

<style scoped>
.kurzgeschichten-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.kurzgeschichten-container1 {
  align-items: center;
  justify-content: center;
}
</style>
