<template>
  <div class="unwichtigster-mensch-container">
    <app-navigation rootClassName="navigation-root-class-name34"></app-navigation>
    <h1 class="page-title">Unwichtigster Mensch</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Konflikt, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1qyeokYSx1yyPlTYtkM1tL-Aalgi3qGBE/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="unwichtigster-mensch-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Ich stehe beim Kiosk in der Warteschlange. Ich bin spät dran und möchte
          mir nur schnell den täglichen Kaffee holen und dann zur Arbeit.
        </span>
        <br />
        <br />
        <span>
          Hinter mir kommt eine gestresste Frau. Wenn die so gestresst aussieht
          ist die bestimmt noch später dran als ich.
        </span>
        <br />
        <span>Ich lasse sie vor.</span>
        <br />
        <br />
        <span>
          Etwas später kommt ein Mann mit ölbefleckter Arbeitskleidung und oranger
          Leuchtweste. Arbeitet wohl am Bahnhof. Vor so strenger Arbeit habe ich
          Respekt. Der braucht den Kaffee eher.
        </span>
        <br />
        <span>Ich lasse ihn vor.</span>
        <br />
        <br />
        <span>Eine alte Dame. Die hat nicht mehr viel Lebenszeit.</span>
        <br />
        <span>Ich lasse sie vor.</span>
        <br />
        <br />
        <span>
          Zwei Eltern und ihr Baby. Die haben schon sonst so wenig Zeit für sich.
        </span>
        <br />
        <span>Ich lasse sie vor.</span>
        <br />
        <br />
        <span>
          Ist das echt der eine Politiker? Ich habe den Namen vergessen, aber der
          ist berühmt. Wichtige Leute sollten nicht warten.
        </span>
        <br />
        <span>Ich lasse ihn vor.</span>
        <br />
        <br />
        <span>
          Eine schwangere Frau. Eine Schwangerschaft ist bestimmt anstrengend.
        </span>
        <br />
        <span>Ich lasse sie vor.</span>
        <br />
        <br />
        <span>
          Oh je, die Person hat’s am Schlimmsten. Schau dir die Tränensäcke an,
          das bleiche Gesicht und die gestresste Haltung. Ich will die Person
          grade vor lassen, als ich merke, dass es mein Spiegelbild im
          Schaufenster ist.
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name23"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'UnwichtigsterMensch',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Unwichtigster Mensch',
    meta: [
      {
        name: 'description',
        content:
          '"Ich stehe beim Kiosk in der Warteschlange. Ich bin spät dran und möchte mir nur schnell den täglichen Kaffee holen und dann zur Arbeit. Hinter mir kommt [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Unwichtigster Mensch',
      },
      {
        property: 'og:description',
        content:
          '"Ich stehe beim Kiosk in der Warteschlange. Ich bin spät dran und möchte mir nur schnell den täglichen Kaffee holen und dann zur Arbeit. Hinter mir kommt [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.unwichtigster-mensch-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.unwichtigster-mensch-download-button {
  text-decoration: none;
}
</style>
