<template>
  <div class="event-list" v-bind:class="rootClassName">
    <div class="event-entry">
      <h3 class="event-title">Run 7 (Schweiz)</h3>
      <div>
        <p class="kommende-letzte-gelegenheit-runs-datum-von event-section">
          5. Juli 2025, 10:00 Uhr bis
        </p>
        <p class="kommende-letzte-gelegenheit-runs-datum-von event-section">
          6. Juli 2025, 14:00 Uhr
        </p>
      </div>
      <p class="event-section">{{ standortWeisslingen }}</p>
      <div>
        <p class="event-green event-section">15 von 15 freie Plätze</p>
        <p class="event-green event-section">Auslosung am 31.08.2024</p>
      </div>
    </div>
    <div
      class="kommende-letzte-gelegenheit-runs-letzte-gelegenheit-run8 event-entry"
    >
      <h3 class="event-title">Run 8 (Schweiz)</h3>
      <div>
        <p class="kommende-letzte-gelegenheit-runs-datum event-section">
          12. Juli 2025, 10:00 Uhr bis
        </p>
        <p class="kommende-letzte-gelegenheit-runs-datum event-section">
          13. Juli 2025, 14:00 Uhr
        </p>
      </div>
      <p class="event-section">{{ standortWeisslingen }}</p>
      <div>
        <p class="event-green event-section">15 von 15 freie Plätze</p>
        <p class="event-green event-section">Auslosung am 31.08.2024</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KommendeLetzteGelegenheitRuns',
  props: {
    rootClassName: String,
    standortWeisslingen: {
      type: String,
      default: 'Zivilschutzanlage Sonnenblock Chilegass 8, 8484 Weisslingen',
    },
  },
}
</script>

<style scoped>
.kommende-letzte-gelegenheit-runs-datum {
  font-weight: 500;
}
.kommende-letzte-gelegenheit-runs-letzte-gelegenheit-run8 {
  border-bottom-width: 3px;
}
</style>
