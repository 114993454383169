<template>
  <div class="was-unterscheidet-himmel-von-hoelle-container">
    <app-navigation rootClassName="navigation-root-class-name24"></app-navigation>
    <h1 class="page-title">Was unterscheidet Himmel von Hölle?</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1vCD8vRj1FgZwoHBqq1a0bhMsIDO4zwY2/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="was-unterscheidet-himmel-von-hoelle-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          “Du glaubst also, es ist schöner im Himmel als in der Hölle zu sein? Und
          nachdem du im Himmel alles erlebt hast, was es zu erleben gibt? Wenn all
          die Schönheiten zur Gewohnheit werden und du des Lebens müde wirst?
          Vielleicht nicht nach Jahrzehnten oder -hunderten, aber Jahrtausenden
          oder Millionen. Wenn das nicht reicht, Jahrmilliarden, irgendeine Zahl
          und rechne da eine eins hinzu.
        </span>
        <br />
        <br />
        <span>
          Ja, irgendwann ist alles, das es zu leben gibt, ausgelebt. Wenn du und
          allfällige Begleiter die Grenzen der Kunst und Kreativität ausgeschöpft
          habt. Was hältst du dann vom himmlischen Gefängnis? Von den Eisenstangen
          aus Harfen, dem weiss-goldenen Verputz? Ist dir dein Himmel dann noch
          immer ein Geschenk? Wenn du nicht weisst, was du tun sollst, nur zu
          warten auf etwas, das nie kommen wird? Ein Ende? Ist die Ewigkeit dann
          nicht auch Strafe? Ewig zu schmoren, in der Hölle dann gar so
          unterschiedlich als dich für immer zu langweilen?
        </span>
        <br />
        <br />
        <span>
          Aber wer weiss, vielleicht hast du ja Glück und der Himmel lässt es zu,
          zu vergessen und du magst dasselbe wieder und wieder erleben, wie eine
          Ameise im Terrarium.”
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name17"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'WasUnterscheidetHimmelVonHoelle',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Was unterscheidet Himmel von Hölle?',
    meta: [
      {
        name: 'description',
        content:
          '"Du glaubst also, es ist schöner im Himmel als in der Hölle zu sein? Und nachdem du im Himmel alles erlebt hast, was es zu erleben gibt? Wenn all die Schönheiten zur Gewohnheit werden und [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Was unterscheidet Himmel von Hölle?',
      },
      {
        property: 'og:description',
        content:
          '"Du glaubst also, es ist schöner im Himmel als in der Hölle zu sein? Und nachdem du im Himmel alles erlebt hast, was es zu erleben gibt? Wenn all die Schönheiten zur Gewohnheit werden und [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.was-unterscheidet-himmel-von-hoelle-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.was-unterscheidet-himmel-von-hoelle-download-button {
  text-decoration: none;
}
</style>
