import { render, staticRenderFns } from "./wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist.vue?vue&type=template&id=71b45d5b&scoped=true"
import script from "./wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist.vue?vue&type=script&lang=js"
export * from "./wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist.vue?vue&type=script&lang=js"
import style0 from "./wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist.vue?vue&type=style&index=0&id=71b45d5b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b45d5b",
  null
  
)

export default component.exports