<template>
  <div class="wie-man-ein-gutes-online-larp-macht-und-wie-nicht-container">
    <app-navigation rootClassName="navigation-root-class-name47"></app-navigation>
    <h1 class="page-title">
      Wie man ein gutes Online LARP macht (und wie nicht)
    </h1>
    <span class="page-subtitle">
      Erfahrungsbericht, ursprünglich geposted auf mitoffenemvisier.com am 12ten
      Januar 2021
    </span>
    <div class="text-body">
      <span>
        Dieser Artikel wird in den nächsten Tagen hier übertragen. In der
        Zwischenzeit kann man sich den Artikel auf archive.org auf folgendem Link
        anschauen:
      </span>
    </div>
    <a
      href="https://web.archive.org/web/20220819114827/https://mitoffenemvisier.com/rollenspiel/larp/wie-man-ein-gutes-online-larp-macht/"
      target="_blank"
      rel="noreferrer noopener"
      class="wie-man-ein-gutes-online-larp-macht-und-wie-nicht-download-button button"
    >
      <span class="button-text">Artikel auf archive.org lesen</span>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name35"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'WieManEinGutesOnlineLARPMachtUndWieNicht',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Wie man ein gutes Online LARP macht (und wie nicht)',
    meta: [
      {
        name: 'description',
        content:
          'Ein gutes Online LARP braucht Immersion, Selbstständigkeit des Spielers und vordefinierte Beziehungen. Wie kann man das erreichen?',
      },
      {
        property: 'og:title',
        content:
          'OpenVisor - Wie man ein gutes Online LARP macht (und wie nicht)',
      },
      {
        property: 'og:description',
        content:
          'Ein gutes Online LARP braucht Immersion, Selbstständigkeit des Spielers und vordefinierte Beziehungen. Wie kann man das erreichen?',
      },
    ],
  },
}
</script>

<style scoped>
.wie-man-ein-gutes-online-larp-macht-und-wie-nicht-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
.wie-man-ein-gutes-online-larp-macht-und-wie-nicht-download-button {
  text-decoration: none;
}
</style>
